import { BREAKPOINT, COLOR } from '../../../styles/variables'
import { css } from 'styled-components'

export const SubHeaderStyles = css`
  background-color: ${COLOR.GREY_LIGHT};
  color: ${COLOR.WHITE};
  display: block;

  button,
  a {
    color: ${COLOR.WHITE};

    &.invisible {
      background-color: transparent;
      color: transparent;
      border: none;
      min-height: 16px;
      outline: none;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    display: none;
  }
`
