import { PAYPAL_URLS } from '../constants'

const asyncLoadPaypal = async () => {
  const env = process.env.NODE_ENV
  if (!env) return

  const PAYPAL_SCRIPT = PAYPAL_URLS[env]
  const script = document.createElement('script')
  script.setAttribute('src', PAYPAL_SCRIPT)
  document.head.appendChild(script)
}

export default asyncLoadPaypal
