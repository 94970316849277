import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import ReactGA from 'react-ga4'
import USER_ACTIONS from './utils/constantExports/userActions'
import fromLocation from './recoil/atoms/fromLocation'
import ftaConfirm from './utils/ftaConfirm'
import { ActiveSports } from '../../types'
import {
  DEFAULT_ERROR_MESSAGE,
  IS_LOCAL_DEV,
  NOTIFICATIONS_KEY,
  USER_FB_LOADED,
} from './utils/constants'
import { SITE_LINKS } from './utils/links'
import {
  handleLoadFacebook,
  requestActiveSports,
  submitAuthenticateUser,
  submitFacebookSignup,
  submitLogin,
  submitLogout,
  submitPaypalCheckout,
  submitPaypalDraftGuideCheckout,
  submitSendForgotUsernameEmail,
  submitSendResetPasswordEmail,
  submitSendVerificationEmail,
  submitSignup,
  submitStripeCheckout,
  submitStripeDraftGuideCheckout,
  submitUnsubscribe,
} from './utils/userRequests'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

const nullUser = {
  isLoading: true,
  isLoggedIn: false,
  isSubscriber: false,
  username: null,
}

export type AccountContextType = {
  activeModal?: string | null
  error?: string | null
  facebookLoaded?: boolean
  inactive?: boolean
  isLoading?: boolean
  isLoggedIn?: boolean
  isSubscriber?: boolean
  navigateUser?: string | null
  obfuscatedEmail?: string
  preferences?: Record<any, any>
  sports: Array<ActiveSports>
  updates: number
  user?: Record<any, any>
  closeModal: () => void
  requestFacebookLogin: (_: boolean) => void
  requestFacebookSignup: (_: any) => void
  requestLogin: (_: any) => void
  requestLogout: () => void
  requestPaypalCheckout: (_: string, __: string) => void
  requestPaypalDraftGuideCheckout: (_: any, __: any, ___: any) => void
  requestSendForgotUsernameEmail: (_: any) => void
  requestSendResetPasswordEmail: (_: any) => void
  requestSendVerificationEmail: (_: string) => void
  requestSignup: (_: any) => void
  requestStripeDraftGuideCheckout: (_: any, __: any, ___: any) => void
  requestStripeCheckout: (_: any, __: any, ___: any) => void
  requestUnsubscribe: () => void
  setActiveModal: Dispatch<SetStateAction<string | null>>
  setError: Dispatch<SetStateAction<string | null>>
  setInactive: Dispatch<SetStateAction<boolean>>
  setUpdates: Dispatch<SetStateAction<number>>
  submitAuthenticateUser: () => void
}

export const AccountContext = React.createContext<AccountContextType>({
  sports: [],
  activeModal: null,
  error: null,
  facebookLoaded: false,
  inactive: false,
  isLoading: true,
  isLoggedIn: false,
  isSubscriber: false,
  navigateUser: null,
  obfuscatedEmail: '',
  preferences: {},
  updates: 0,
  user: {},
  closeModal: () => {},
  submitAuthenticateUser: () => {},
  requestFacebookLogin: () => {},
  requestFacebookSignup: () => {},
  requestLogin: () => {},
  requestLogout: () => {},
  requestPaypalCheckout: () => {},
  requestPaypalDraftGuideCheckout: () => {},
  requestSendForgotUsernameEmail: () => {},
  requestSendResetPasswordEmail: () => {},
  requestSendVerificationEmail: () => {},
  requestSignup: () => {},
  requestStripeDraftGuideCheckout: () => {},
  requestStripeCheckout: () => {},
  requestUnsubscribe: () => {},
  setActiveModal: () => {},
  setError: () => {},
  setInactive: () => {},
  setUpdates: () => {},
})

export const AccountConsumer = AccountContext.Consumer

// Need an enum for active modals
const AccountProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const [from, setFrom] = useRecoilState(fromLocation)
  const [sports, setSports] = useState([])
  const [activeModal, setActiveModal] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [facebookLoaded, setFacebookLoaded] = useState(false)
  const [inactive, setInactive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isSubscriber, setIsSubscriber] = useState(false)
  const [obfuscatedEmail, setObfuscatedEmail] = useState('')
  const [preferences, setPreferences] = useState({})
  const [updates, setUpdates] = useState(0)
  const [user, setUser] = useState(nullUser)
  const [navigateUser, setNavigateUser] = useState<string | null>(null)

  useEffect(() => {
    localStorage.setItem(
      USER_FB_LOADED,
      JSON.stringify({ facebookLoaded: false })
    )

    handleLoadFacebook()
    setFacebookLoaded(true)
  }, [])

  useEffect(() => {
    // Using this to open modal after navigation
    if (from === USER_ACTIONS.login) {
      setActiveModal(from)
      setFrom(SITE_LINKS.HOME)
    }
  }, [from, setFrom])

  const { pathname } = window.location

  useEffect(() => {
    if (!IS_LOCAL_DEV) ReactGA.initialize('UA-54319723-1')

    const isBot = navigator.webdriver
    const { title } = document

    if (!isBot && !IS_LOCAL_DEV) {
      ReactGA.send({ hitType: 'pageview', page: pathname, title: title })
    }
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const initializeUser = async () => {
      setIsLoading(true)

      const {
        isLoggedIn,
        isSubscriber,
        user: newUser,
      } = await submitAuthenticateUser()

      if (isLoggedIn) {
        setIsLoggedIn(isLoggedIn)
        setIsSubscriber(isSubscriber)
        setPreferences(newUser.preferences)
        setUser(newUser)

        if (newUser.preferences?.notifications) {
          localStorage.setItem(
            NOTIFICATIONS_KEY,
            newUser.preferences.notifications
          )
        }
      } else {
        setUser(newUser)
      }

      const newActiveSports = await requestActiveSports()

      setSports(newActiveSports)

      if (navigateUser) {
        setIsLoggedIn(false)
        setNavigateUser(null)
        navigate(navigateUser)
      }

      setIsLoading(false)
    }

    initializeUser()
  }, [isLoggedIn, pathname, updates, navigate, navigateUser, setNavigateUser])

  const closeModal = () => {
    setActiveModal(null)
    setError(null)
  }

  const executeRequest = async (
    callback: any,
    activeModalString?: string,
    callbackData?: any,
    navigateTo?: string,
    refresh?: boolean
  ) => {
    const { errorResponse } = await callback(callbackData)
    if (errorResponse) setError(errorResponse || DEFAULT_ERROR_MESSAGE)
    else if (activeModalString) setActiveModal(activeModalString)
    else if (navigateTo) setNavigateUser(navigateTo)
    setIsLoading(false)

    if (refresh) window.location.reload()

    return errorResponse
  }

  const requestLogin = async (form: any) => {
    setIsLoading(true)
    const { isSub, userResponse, errorResponse } = await submitLogin(form)

    if (errorResponse) {
      setError(errorResponse || DEFAULT_ERROR_MESSAGE)
    } else {
      setUser(userResponse)
      setIsSubscriber(isSub)
      setError(null)

      if (userResponse.requiresActivation) {
        setIsLoggedIn(true)
        setFrom(SITE_LINKS.HOME)
        setActiveModal(USER_ACTIONS.emailVerificationRequired)
      } else if (userResponse.passwordResetRequired) {
        setObfuscatedEmail(userResponse.obfuscatedEmailAddress)
        setActiveModal(USER_ACTIONS.passwordResetRequired)
      } else {
        setIsLoggedIn(true)
        closeModal()
        if (from !== window.location.pathname) setNavigateUser(from)
        setUpdates(updates + 1)
      }
    }
    setIsLoading(false)
  }

  const requestLogout = async () => {
    setIsLoading(true)
    setUser(nullUser)
    setIsLoggedIn(false)
    setIsSubscriber(false)
    await submitLogout()
    setNavigateUser(SITE_LINKS.HOME)
    setIsLoading(false)
  }

  const requestSendVerificationEmail = async (emailAddress: string) =>
    executeRequest(
      submitSendVerificationEmail,
      USER_ACTIONS.verificationEmailSent,
      emailAddress
    )

  const requestSendResetPasswordEmail = async (form: any) =>
    executeRequest(
      submitSendResetPasswordEmail,
      USER_ACTIONS.resetPasswordEmailSent,
      form
    )

  const requestSignup = async (form: any) =>
    executeRequest(submitSignup, USER_ACTIONS.verificationEmailSent, form)

  const requestSendForgotUsernameEmail = (form: any) =>
    executeRequest(
      submitSendForgotUsernameEmail,
      USER_ACTIONS.forgotUsernameEmailSent,
      form
    )

  const requestUnsubscribe = async () => {
    const { isConfirmed } = await ftaConfirm({
      text: 'Cancel your FTA+ subscription?',
    })

    if (!isConfirmed) return null

    return executeRequest(submitUnsubscribe)
  }

  const requestStripeCheckout = async (
    token: string,
    plan: string,
    discountCode?: string
  ) =>
    executeRequest(
      submitStripeCheckout,
      '',
      {
        discountCode,
        plan,
        token,
      },
      SITE_LINKS.FTA_PLUS_WELCOME
    )

  const requestStripeDraftGuideCheckout = async (
    token: string,
    productId: string,
    pricing: string
  ) =>
    executeRequest(
      submitStripeDraftGuideCheckout,
      USER_ACTIONS.checkoutDraftGuideComplete,
      {
        token,
        productId,
        pricing,
      },
      undefined,
      true
    )

  const requestPaypalCheckout = (subscriptionId: string, plan: string) =>
    executeRequest(
      submitPaypalCheckout,
      '',
      {
        subscriptionId,
        plan,
      },
      SITE_LINKS.FTA_PLUS_WELCOME
    )

  const requestPaypalDraftGuideCheckout = (
    orderID: string,
    productId: string,
    pricing: string
  ) =>
    executeRequest(
      submitPaypalDraftGuideCheckout,
      USER_ACTIONS.checkoutDraftGuideComplete,
      {
        orderID,
        productId,
        pricing,
      },
      undefined,
      true
    )

  const requestFacebookLogin = async (isSubscriber: boolean) => {
    setIsLoggedIn(true)
    setIsSubscriber(isSubscriber)
    setError(null)
    setUpdates(updates + 1)
    setActiveModal(null)
  }

  const requestFacebookSignup = (form: any) =>
    executeRequest(submitFacebookSignup, '', form, SITE_LINKS.FTA_WELCOME)

  const contextProvided = {
    sports,
    activeModal,
    closeModal,
    error,
    facebookLoaded,
    inactive,
    isLoading,
    isLoggedIn,
    isSubscriber,
    obfuscatedEmail,
    preferences,
    submitAuthenticateUser,
    requestFacebookLogin,
    requestFacebookSignup,
    requestLogin,
    requestLogout,
    requestPaypalCheckout,
    requestPaypalDraftGuideCheckout,
    requestSendForgotUsernameEmail,
    requestSendResetPasswordEmail,
    requestSendVerificationEmail,
    requestSignup,
    requestStripeDraftGuideCheckout,
    requestStripeCheckout,
    requestUnsubscribe,
    setActiveModal,
    setError,
    setInactive,
    setUpdates,
    updates,
    user,
  }

  return (
    <AccountContext.Provider value={contextProvided}>
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider
