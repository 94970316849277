import MODAL_TITLES from '../../../utils/constantExports/modalTitles'
import ReactGA from 'react-ga4'
import USER_ACTIONS from '../../../utils/constantExports/userActions'
import logo from '../../../assets/images/ftaMiniLogo.png'
import styled from 'styled-components'
import { AccountContext } from '../../../AccountProvider'
import {
  BUTTON_STATES,
  IS_LOCAL_DEV,
  JOIN_FTA,
  LOG_IN,
  SIGN_UP,
  USER_NOTIFICATIONS,
  USER_ROLES,
  VARIANTS,
} from '../../../utils/constants'
import { COLOR } from '../../../styles/variables'
import { EmailIcon, MenuIcon, RememberMeIcon } from '../../common/icons'
import { HeaderStyles } from './headerStyles'
import { Link } from 'react-router-dom'
import { lazy, useContext, useState } from 'react'
import { notifySuccess } from '../../../utils/helpers'

const ChatButton = lazy(() => import('../../common/buttons/ChatButton'))
const ColorButton = lazy(() => import('../../common/buttons/ColorButton'))
const Container = lazy(() => import('../../common/container/Container'))
const FacebookSignupForm = lazy(() => import('../../forms/FacebookSignupForm'))
const LoginButton = lazy(() => import('../../common/buttons/LoginButton'))
const LoginForm = lazy(() => import('../../forms/LoginForm'))
const MobileMenu = lazy(() => import('../mobile-menu/MobileMenu'))
const Modal = lazy(() => import('../../common/modal/Modal'))
const SendForgotUsernameForm = lazy(
  () => import('../../forms/SendForgotUsernameForm')
)
const SendResetPasswordEmailForm = lazy(
  () => import('../../forms/SendResetPasswordEmailForm')
)
const SignupForm = lazy(() => import('../../forms/SignupForm'))
const Spacer = lazy(() => import('../../common/spacer/Spacer'))
const StripeUpdateModal = lazy(
  () => import('../../routes/memberships/StripeUpdateModal')
)
const UserMenuButtons = lazy(() => import('../UserMenuButtons'))

const HeaderDiv = styled.header`
  ${HeaderStyles}
`
export const testIdHeader = 'header'
export const testIdLoginModal = 'loginModal'
export const testIdSignUpModal = 'signUpModal'

const MODAL_TEST_IDS: Record<string, string> = {
  [USER_ACTIONS.emailVerificationRequired]: 'Email Requires Verification',
  [USER_ACTIONS.forgotUsernameEmailSent]: 'Email sent',
  [LOG_IN]: testIdLoginModal,
  [USER_ACTIONS.passwordResetRequired]: 'Reset your password',
  [USER_ACTIONS.resetPasswordEmailSent]: 'Password reset email sent',
  [USER_ACTIONS.checkoutComplete]: 'Checkout Complete',
  [USER_ACTIONS.checkoutDraftGuideComplete]: 'Draft Guide Checkout Complete',
  [USER_ACTIONS.sendForgotUsernameEmail]: 'Forgot username?',
  [USER_ACTIONS.sendResetPasswordEmail]: 'Send password reset email',
  [SIGN_UP]: testIdSignUpModal,
  [USER_ACTIONS.facebookSignup]: `Sign up With Facebook`,
  [USER_ACTIONS.verificationEmailSent]: 'Verification Email Sent',
  [USER_ACTIONS.updateCardInfo]: 'Update Card Info',
}

const Header = () => {
  const account = useContext(AccountContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleFormSubmit = (form: any) => {
    const {
      requestFacebookSignup,
      requestLogin,
      requestSignup,
      requestSendForgotUsernameEmail,
      requestSendResetPasswordEmail,
    } = account

    const formSubmitMap = {
      [USER_ACTIONS.login]: requestLogin,
      [USER_ACTIONS.signup]: requestSignup,
      [USER_ACTIONS.facebookSignup]: requestFacebookSignup,
      [USER_ACTIONS.sendForgotUsernameEmail]: requestSendForgotUsernameEmail,
      [USER_ACTIONS.sendResetPasswordEmail]: requestSendResetPasswordEmail,
    }

    if (!account?.activeModal) return
    formSubmitMap[account.activeModal](form)
  }

  const handleRequestSendVerificationEmail = () => {
    const { requestSendVerificationEmail, user } = account

    const activeEmailAddress = user && user.emailAddress

    if (activeEmailAddress) {
      notifySuccess(
        'Sending email activation. Please check inbox and spam folders'
      )
      account.setActiveModal(null)
      requestSendVerificationEmail(activeEmailAddress)
    }
  }

  const handleActivatePasswordReset = () => {
    account.setActiveModal(USER_ACTIONS.sendResetPasswordEmail)
  }

  const getModalForm = (activeModal: string) => {
    if (!IS_LOCAL_DEV)
      ReactGA.send({ hitType: 'modalview', modal: activeModal })

    if (activeModal === USER_ACTIONS.login) {
      return <LoginForm error={account.error} onSubmit={handleFormSubmit} />
    }

    if (activeModal === USER_ACTIONS.sendForgotUsernameEmail) {
      return (
        <SendForgotUsernameForm
          error={account.error}
          onSubmit={handleFormSubmit}
        />
      )
    }

    if (activeModal === USER_ACTIONS.sendResetPasswordEmail) {
      return (
        <SendResetPasswordEmailForm
          error={account.error}
          onSubmit={handleFormSubmit}
        />
      )
    }

    if (activeModal === USER_ACTIONS.signup) {
      return <SignupForm error={account.error} onSubmit={handleFormSubmit} />
    }

    if (activeModal === USER_ACTIONS.facebookSignup) {
      return (
        <FacebookSignupForm error={account.error} onSubmit={handleFormSubmit} />
      )
    }

    if (activeModal === USER_ACTIONS.updateCardInfo) {
      return <StripeUpdateModal error={account.error} />
    }

    return null
  }

  const entitledRoles = [USER_ROLES.siteAdmin, USER_ROLES.developer]

  const fullWidthCenter = {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
    width: '100%',
  }

  const modalContentMap = {
    [USER_ACTIONS.checkoutDraftGuideComplete]: <div style={fullWidthCenter} />,
    [USER_ACTIONS.verificationEmailSent]: (
      <div style={fullWidthCenter}>
        <ColorButton
          endIcon={<EmailIcon />}
          onClick={() => {
            handleRequestSendVerificationEmail()
            account.setActiveModal(null)
          }}
          size="large"
          text="Resend Email"
          variant={VARIANTS.CONTAINED}
        />
      </div>
    ),
    [USER_ACTIONS.emailVerificationRequired]: (
      <div style={fullWidthCenter}>
        <ColorButton
          endIcon={<EmailIcon />}
          onClick={() => {
            handleRequestSendVerificationEmail()
            account.setActiveModal(USER_ACTIONS.verificationEmailSent)
          }}
          size="large"
          text="Send Verification Email"
          variant={VARIANTS.CONTAINED}
        />
      </div>
    ),
    [USER_ACTIONS.passwordResetRequired]: (
      <div style={fullWidthCenter}>
        <ColorButton
          endIcon={<EmailIcon />}
          onClick={handleActivatePasswordReset}
          size="large"
          text="Send Password Reset Email"
          variant={VARIANTS.CONTAINED}
        />
      </div>
    ),
    [USER_ACTIONS.accountCreated]: (
      <div style={fullWidthCenter}>
        <ColorButton
          link="/memberships"
          onClick={() => account.setActiveModal(null)}
          size="large"
          startIcon={<RememberMeIcon />}
          text="View Membership Options"
          variant={VARIANTS.CONTAINED}
        />
      </div>
    ),
    [USER_ACTIONS.checkoutComplete]: (
      <Spacer noX>
        <ChatButton />
      </Spacer>
    ),
  }

  const { activeModal, closeModal, isLoading, isLoggedIn, isSubscriber, user } =
    account

  const { username, roles } = user || {}
  const isEntitled =
    roles && !!entitledRoles.filter((item) => roles[item]).length
  const isMembershipsPage = window.location.pathname === '/memberships'

  return (
    <HeaderDiv data-testid={testIdHeader}>
      <Container background={COLOR.BLUE_DARK2}>
        <Spacer noX>
          {isMenuOpen ? (
            <MobileMenu
              isEntitled={isEntitled}
              isLoggedIn={!!isLoggedIn}
              isMembershipsPage={isMembershipsPage}
              isSubscriber={!!isSubscriber}
              onMenuToggle={setIsMenuOpen}
              username={username}
            />
          ) : (
            <Spacer noY responsive>
              <div className="inner-header">
                <div className="logo-wrapper">
                  <Link to="/">
                    <img alt="Logo" height={30} src={logo} width={100} />
                  </Link>
                </div>
                <div className="user-menu">
                  <UserMenuButtons
                    isEntitled={isEntitled}
                    isLoggedIn={!!isLoggedIn}
                    isMembershipsPage={isMembershipsPage}
                    isSubscriber={!!isSubscriber}
                    onMenuToggle={setIsMenuOpen}
                    username={username}
                  />
                </div>
                <div className="mobile-menu">
                  {isLoggedIn &&
                    !isSubscriber &&
                    !isMenuOpen &&
                    !isMembershipsPage && (
                      <div>
                        <ColorButton
                          buttonColor={BUTTON_STATES.SUCCESS}
                          link="/memberships"
                          onClick={() => account.setActiveModal(null)}
                          size="small"
                          startIcon={<RememberMeIcon />}
                          text={JOIN_FTA}
                          variant={VARIANTS.CONTAINED}
                        />
                      </div>
                    )}
                  {!isLoading && !isLoggedIn && !isMenuOpen && <LoginButton />}
                  {!isMenuOpen && (
                    <MenuIcon
                      fontSize="large"
                      onClick={() => setIsMenuOpen(true)}
                      style={{ color: COLOR.WHITE }}
                    />
                  )}
                </div>
              </div>
            </Spacer>
          )}
        </Spacer>
      </Container>
      {activeModal && (
        <Modal
          dataTestId={MODAL_TEST_IDS[MODAL_TITLES[activeModal]] || 'FALLBACK'}
          isModalOpen
          modalHeader={MODAL_TITLES[activeModal]}
          onModalClose={() => {
            closeModal()
          }}
        >
          {USER_NOTIFICATIONS[activeModal] && (
            <div
              dangerouslySetInnerHTML={{
                __html: USER_NOTIFICATIONS[activeModal],
              }}
              style={{ fontSize: '18px' }}
            />
          )}
          {getModalForm(activeModal)}
          {modalContentMap[activeModal] || null}
        </Modal>
      )}
      {}
    </HeaderDiv>
  )
}

export default Header
