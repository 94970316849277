import Header from './components/application/header/Header'
import LoadingSpinner from './components/common/loading/LoadingSpinner'
import SubHeader from './components/application/sub-header/SubHeader'

const LoadingFta = () => (
  <>
    <Header />
    <SubHeader />
    <div style={{ minHeight: '90vh', position: 'relative' }}>
      <LoadingSpinner text="Loading FTA" />
    </div>
  </>
)

export default LoadingFta
