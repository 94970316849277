const USER_ACTIONS = {
  accountCreated: 'accountCreated',
  facebookSignup: 'facebookSignup',
  forgotUsernameEmailSent: 'forgotUsernameEmailSent',
  login: 'login',
  signup: 'signup',
  verificationEmailSent: 'verificationEmailSent',
  resetPasswordEmailSent: 'resetPasswordEmailSent',
  sendForgotUsernameEmail: 'sendForgotUsernameEmail',
  sendResetPasswordEmail: 'sendResetPasswordEmail',
  checkoutComplete: 'checkoutComplete',
  checkoutDraftGuideComplete: 'checkoutDraftGuideComplete',
  emailVerificationRequired: 'emailVerificationRequired',
  passwordResetRequired: 'passwordResetRequired',
  userRolesUpdated: 'userRolesUpdated',
  updateCardInfo: 'updateCardInfo',
}

export default USER_ACTIONS
