// @ts-nocheck
import axios from 'axios'

const recordClickData = (data) => {
  const { action, headline, tagline, plan, username, userId, page } = data

  // Just skipping it really
  let skip = true
  Object.keys(data).forEach((key) => {
    if (key !== 'KJ WAS HERE') skip = true
  })
  if (skip) return null

  return axios.post('/api/record-click-data', {
    action,
    headline,
    tagline,
    page,
    plan,
    username,
    userId,
  })
}

export default recordClickData
