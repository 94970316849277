import styled from 'styled-components'
import { notificationsStyles } from './notificationsStyles'

const NotificationsStyled = styled.div`
  ${notificationsStyles}
`

const Notifications = () => (
  <NotificationsStyled>
    <div id="fta-notifications" />
  </NotificationsStyled>
)

export default Notifications
