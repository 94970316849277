// @ts-nocheck
import { BREAKPOINT, COLOR } from '../../../styles/variables'
import { css } from 'styled-components'

export const ContainerStyles = css`
  background-color: ${({ background }) => background || 'transparent'};
  box-sizing: border-box;
  margin: 0 1rem;
  padding: 0;

  &.grey {
    background-color: ${COLOR.GREY_LIGHT};
  }

  &.grey-light {
    background-color: ${COLOR.GREY_LIGHT};
  }

  &.white {
    background-color: $white;
  }

  &.light {
    background-color: ${COLOR.GREY_LIGHT2};
  }

  h2,
  h3 {
    font-size: 1.25em;
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    margin: 0;
    padding: 0 3px;
  }
`
