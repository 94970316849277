import CtaButton from '../../common/cta-button/CtaButton'
import MENU_PAGES from '../../../utils/constantExports/menuPages'
import Memberships from '../memberships/Memberships'
import axios from 'axios'
import recordClickData from '../../../utils/helperFunctions/recordClickData'
import styled from 'styled-components'
import { AccountContext } from '../../../AccountProvider'
import { Link } from 'react-router-dom'
import { ROUTES, TODAY_PRETTY_DATE } from '../../../utils/constants'
import { containerStyles } from '../nba-pages/nbaPageStyles'
import { parseResponse } from '../../../utils/helpers'
import { useContext, useEffect, useState } from 'react'

interface StyledTdProps {
  totalColor: string
  value: number // Current value for the progress bar
  maxValue: number // Maximum value for the progress bar
}

const StyledTd = styled.td<StyledTdProps>`
  position: relative;
  padding: 10px;
  vertical-align: middle;

  // Progress Bar Container
  .progress-bar-container {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    margin-top: 4px; // Adjust spacing between the number and the progress bar
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Adds a subtle shadow around the container
  }

  // Progress Bar
  .progress-bar {
    height: 100%;
    background-color: ${(props) => props.totalColor};
    width: ${(props) => (props.value / props.maxValue) * 100}%;
  }
`

const MlbIrt = () => {
  const [sortBy, setSortBy] = useState('')
  const [inverted, setInverted] = useState('')
  const [irt, setIrt] = useState<
    {
      opponent: string
      opposingPitcher: string
      opposingPitcherThrows: string
      team: string
      teamTotal: number
      teamTotalColor: string
      gameTime: string
      matchup: string
      gameTotal: number
      gameTotalColor: string
    }[]
  >([])
  const account = useContext(AccountContext)
  const { user } = account

  useEffect(() => {
    const fetchIrt = async () => {
      const irtData = await axios
        .get(ROUTES.MLB_IRT)
        .then((res) => parseResponse(res))
      setIrt(irtData)
    }
    fetchIrt()
  }, [])

  const isSubscriber = account && account.isSubscriber
  const username = user?.username || null
  const userId = user && user?.id

  const ContainerDiv = styled.div`
    ${containerStyles}
  `

  const handleSort = (sort: string, invert = false) => {
    if (inverted) setInverted('')
    if (invert && !inverted) setInverted(sort)
    if (sortBy === sort && !inverted) setInverted(sort)
    setSortBy(sort)
  }

  if (sortBy) {
    irt.sort((a: any, b: any) => {
      if (['opposingPitcher', 'team'].includes(sortBy)) {
        if (inverted === sortBy) return a[sortBy].localeCompare(b[sortBy])
        return b[sortBy].localeCompare(a[sortBy])
      } else {
        if (inverted === sortBy) return a[sortBy] - b[sortBy]
        return b[sortBy] - a[sortBy]
      }
    })
  }

  return (
    <ContainerDiv>
      <h2>{`MLB Implied Runs Today | ${TODAY_PRETTY_DATE}`}</h2>
      <h3>Implied runs for each MLB team today, based on vegas odds</h3>
      {!isSubscriber && (
        <div className="memberships-link">
          <CtaButton
            link="/memberships"
            onClick={() => {
              recordClickData({
                action: 'subscribe',
                headline: 'Ready To Crush Draftkings? Join FTA+',
                tagline: 'Top - MLB Implied Runs',
                username,
                userId,
                page: 'mlbIrt',
              })
            }}
            text="Ready To Crush Draftkings? Join FTA+"
          />
        </div>
      )}
      {irt.length > 0 && (
        <div className="table-container">
          <table>
            <tbody>
              <tr>
                <th
                  className="sortable"
                  onClick={() => handleSort('team', true)}
                >
                  Team
                </th>
                <th
                  className="sortable"
                  onClick={() => handleSort('teamTotal', true)}
                >
                  Implied Runs
                </th>
                <th
                  className="sortable"
                  onClick={() => handleSort('opposingPitcher', true)}
                >
                  Opposing Pitcher
                </th>
                <th
                  className="sortable"
                  onClick={() => handleSort('gameTotal', true)}
                >
                  Game Total
                </th>
                <th>Game Time</th>
              </tr>
              {irt.map((team, i) => {
                const even = i % 2 ? 'even light' : 'odd light'

                return (
                  <tr className={even} key={team.team + i}>
                    <td>{team.team}</td>
                    <StyledTd
                      maxValue={6}
                      totalColor={team.teamTotalColor}
                      value={team.teamTotal}
                    >
                      {team.teamTotal}
                      <div className="progress-bar-container">
                        <div className="progress-bar"></div>
                      </div>
                    </StyledTd>
                    <td>{`${team.opposingPitcher} (${team.opposingPitcherThrows}) - ${team.opponent}`}</td>
                    <StyledTd
                      maxValue={12}
                      totalColor={team.gameTotalColor}
                      value={team.gameTotal}
                    >
                      {team.gameTotal}
                      <div className="progress-bar-container">
                        <div className="progress-bar"></div>
                      </div>
                    </StyledTd>
                    <td>{team.gameTime}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="dfs-mlb-explanation" style={{ marginTop: '10px' }}>
        {`Want to view the starting lineups for tonight's MLB games? Check out our`}{' '}
        <Link to={MENU_PAGES.MLB['Starting Lineups']}>
          MLB Starting Lineups
        </Link>{' '}
        page, which will also offer the implied runs for each lineup today.
      </div>
      <div className="dfs-mlb-explanation">
        <h3>Understanding MLB Implied Runs: A Comprehensive Guide</h3>
        <p>
          Implied runs are a critical component of MLB DFS research. They are
          calculated by sportsbooks and represent the number of runs that a team
          is expected to score in a given game. The implied run total is based
          on a variety of factors, including the opposing pitcher, the ballpark,
          and the weather conditions.
        </p>
        <p>
          {`In the world of Major League Baseball (MLB), the concept of 'implied
          runs' has become a crucial metric for analysts, bettors, and fans
          alike. Implied runs give us a glimpse into the expected performance of
          a team in a given game, based on the betting odds set by sportsbooks.
          This metric is derived from the over/under (total runs expected in a
          game) and the moneyline (odds of winning) for each team, offering
          insights into which teams are expected to score more runs and,
          consequently, are more likely to win.`}
        </p>
      </div>
      <div className="dfs-mlb-explanation">
        {`Want to view the starting pitchers for tonight's MLB games? Check out our`}{' '}
        <Link to={MENU_PAGES.MLB['Probable Starters']}>
          MLB Probable Pitchers
        </Link>{' '}
        page
      </div>
      <div className="dfs-mlb-explanation">
        <h3>Why Implied Runs Matter in MLB</h3>
        <p>
          Implied runs are a key factor in MLB DFS strategy. They help us
          identify which teams are expected to score the most runs in a given
          game, which can inform our lineup construction. By targeting teams
          with high implied run totals, we can increase our chances of rostering
          players who will produce fantasy points.
        </p>
        <p>
          {`The significance of implied runs in MLB cannot be overstated. For
          analysts, it provides a data-driven way to assess a team's offensive
          strength in a specific matchup, taking into account various factors
          such as player performance, historical matchups, and even weather
          conditions. Bettors use implied runs to make informed decisions on
          where to place their wagers, while fans might look at implied runs to
          set their expectations for a game or to make fantasy baseball
          decisions.`}
        </p>
      </div>
      <div className="dfs-mlb-explanation">
        <h3>Calculating Implied Runs</h3>
        <p>
          {`Calculating implied runs involves a bit of math, but it's
          straightforward once you understand the basics. The formula takes the
          total runs expected in a game (the over/under) and adjusts it based on
          the odds of each team winning (the moneyline). This calculation gives
          us an estimate of how many runs each team is expected to contribute to
          the total, thus providing a clearer picture of the game's dynamics.`}
        </p>
        <p>
          Implied runs are calculated using the over/under and moneyline odds
          for a game. The over/under is the total number of runs expected to be
          scored in a game, while the moneyline represents the odds of a team
          winning. By combining these two metrics, we can determine how many
          runs each team is expected to score.
        </p>
      </div>
      <div className="dfs-mlb-explanation">
        <h3>The Impact of Implied Runs on Game Strategy</h3>
        <p>
          Implied runs can have a significant impact on game strategy in MLB
          DFS. By targeting teams with high implied run totals, we can identify
          offenses that are expected to perform well and generate fantasy
          points. Conversely, we can also look for teams with low implied run
          totals to identify potential pitching matchups that may be favorable
          for pitchers.
        </p>
        <p>
          {`For teams and managers, understanding the concept of implied runs can
          influence game strategy. Knowing the expectations can help in
          decision-making processes, from setting the batting order to deciding
          when to pull a pitcher. It's a tool that complements traditional
          scouting and analytics, providing another layer of strategic depth to
          the game of baseball.`}
        </p>
        <p>
          {`In conclusion, 'mlb implied runs' is more than
          just a statistic; it's a window into the strategic heart of baseball,
          offering insights that can influence betting markets, team strategies,
          and fan engagement. As the MLB continues to evolve, the role of data
          and analytics, including metrics like implied runs, will only grow in
          importance, shaping the future of America's pastime.`}
        </p>
      </div>
      <Memberships otherPage />
    </ContainerDiv>
  )
}

export default MlbIrt
