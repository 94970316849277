// AxiosInstance is defined, not sure why eslint cant find it
// eslint-disable-next-line
import axios, { AxiosInstance } from 'axios'
import { IS_TEST_ENV } from './utils/constants'
import { ReactNode, createContext, useMemo, useReducer } from 'react'

export type FtaAxios = AxiosInstance | null

// Define the context
export const AxiosContext = createContext({
  activeRequests: 0,
  ftaAxios: null as FtaAxios,
})

const START = 'REQUEST_START'
const END = 'REQUEST_END'
// Define the reducer
const loadingReducer = (state: number, action: { type: string }) => {
  switch (action.type) {
    case START:
      return state + 1
    case END:
      return state - 1
    default:
      return state
  }
}

// Define the context provider
export const AxiosProvider = ({ children }: { children: ReactNode }) => {
  const [activeRequests, dispatch] = useReducer(loadingReducer, 0)

  // Wrap axios calls
  const axiosInstance = useMemo(() => {
    const instance = axios.create()
    if (IS_TEST_ENV) return instance

    instance.interceptors.request.use((config) => {
      dispatch({ type: 'REQUEST_START' })
      return config
    })
    instance.interceptors.response.use(
      (response) => {
        dispatch({ type: 'REQUEST_END' })
        return response
      },
      (error) => {
        dispatch({ type: 'REQUEST_END' })
        return Promise.reject(error)
      }
    )
    return instance
  }, [])

  return (
    <AxiosContext.Provider
      value={{
        activeRequests,
        ftaAxios: axiosInstance,
      }}
    >
      {children}
    </AxiosContext.Provider>
  )
}
