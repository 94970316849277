import App from './client/App'
import ErrorBoundary from './client/components/application/ErrorBoundary'
import LoadingFta from './client/LoadingFta'
import Notifications from './client/components/common/notifications/Notifications'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary>
    <Notifications />
    <BrowserRouter>
      <Suspense fallback={<LoadingFta />}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </Suspense>
    </BrowserRouter>
  </ErrorBoundary>
)
