export const COLOR = {
  BLACK: '#000',
  BLUE_DARK: '#2b79d8',
  BLUE_DARK2: '#1b579b',
  BLUE_DODGER: 'dodgerblue',
  BLUE_LIGHT: '#1089ed',
  BLUE_LIGHT2: '#ADD8E6',
  BLUE_LIGHT_SKY: '#87CEFA',
  BLUE_BRIGHT: '#00d2fe',
  DK_GREEN: '#9AC434',
  DK_ORANGE: '#F46C22',
  ERROR: '#F27474',
  FD_BLUE: '#1493FF',
  SHADOW: '#a9aaaf',
  FD_GREEN: '#2CB459',
  FONT_DARK: '#222',
  FONT_GREEN: '#30c13e',
  FT_BLUE: '#3276b1',
  GOLD: '#D4AF37',
  GREEN: 'green',
  GREEN_DARK: '#45e858',
  GREEN_LIGHT: '#6de879',
  GREY_DARK: '#212121',
  GREY_DARK2: '#888888',
  GREY_LIGHT2: '#F8F8F8',
  GREY_LIGHT3: ' #9e9e9e',
  GREY_LIGHT: '#424242',
  GREY: '#303030',
  GREY2: '#D8D8D8',
  ORANGE: '#F27836',
  LIGHT_RED: '#F08080',
  PINK: '#FF1493',
  PURPLE: '#BB86FC',
  RED_DARK: '#ea3a3a',
  RED: '#FF0000',
  RED_LIGHT: '#f27474',
  SILVER: '#C0C0C0',
  TEAL: '#03DAC6',
  WHITE_DARK: '#FAFAFA',
  WHITE_GREY: '#F4F4F4',
  WHITE: '#FFF',
  Y_PURPLE: '#7B0099',
  Y_PURPLE2: '#400090',
  YELLOW_DARK: '#f4cb64',
  YELLOW_LIGHT: '#efd28f',
}

export const FONT_COLOR = {
  DARK: '#222',
}

export const DARK_MODE = {
  ERROR: COLOR.ERROR,
  BG_DARK: COLOR.GREY_DARK,
  BG: COLOR.GREY,
  BG_LIGHT: COLOR.GREY_LIGHT,
  HOVER: COLOR.BLUE_DODGER,
  FOCUS: COLOR.PURPLE,
  PRIMARY: COLOR.ORANGE,
  PRIMARY_TEXT: COLOR.WHITE,
  SECONDARY: COLOR.PURPLE,
  SECONDARY_TEXT: COLOR.WHITE,
  TERTIARY: COLOR.TEAL,
  TERTIARY_TEXT: COLOR.GREY,
}

export const FONT_WEIGHTS = {
  LIGHT: 100,
  REGULAR: 400,
  BOLD: 700,
}

export const SPACING = {
  X: '18px',
  X_LARGE: '36px',
  Y: '10px',
  Y_LARGE: '20px',
}

export const LIGHT_MODE = {
  ERROR: COLOR.ERROR,
  BG_DARK: COLOR.GREY2,
  BG: COLOR.WHITE,
  BG_LIGHT: COLOR.GREY_LIGHT2,
  HOVER: COLOR.BLUE_DODGER,
  FOCUS: COLOR.PURPLE,
  PRIMARY: COLOR.ORANGE,
  PRIMARY_TEXT: COLOR.FONT_DARK,
  SECONDARY: COLOR.PURPLE,
  SECONDARY_TEXT: COLOR.FONT_DARK,
  TERTIARY: COLOR.TEAL,
  TERTIARY_TEXT: COLOR.GREY_LIGHT2,
}

export const COLORS: Record<string, any> = {
  dark: DARK_MODE,
  light: LIGHT_MODE,
}

export const BREAKPOINT = {
  SMALL: '568px',
  MEDIUM: '768px',
  LARGE: '1024px',
  XLARGE: '1280px',
}

export const SCREEN_SIZES = {
  XSMALL_MAX: '567px',
  SMALL_MIN: '568px',
  SMALL_MAX: '767px',
  MEDIUM_MIN: '768px',
  MEDIUM_MAX: '1023px',
  LARGE_MIN: '1024px',
  LARGE_MAX: '1279px',
  XLARGE_MIN: '1280px',
}

export const GRADIENT = {
  BLUE_GREY: `${COLOR.BLUE_DARK2}, ${COLOR.GREY_DARK}`,
  PINK_BLUE: '#e66465, #1b579b',
  TEAL_BLUE: '#00d2fe, #3a7bd5',
  GREY_BLUE: `${COLOR.GREY_DARK}, ${COLOR.BLUE_DARK2}`,
  Draftkings: `${COLOR.ORANGE}, ${COLOR.GREY_DARK}`,
  Fanduel: `${COLOR.FD_GREEN}, ${COLOR.GREY_DARK}`,
  Yahoo: `${COLOR.Y_PURPLE}, ${COLOR.GREY_DARK}`,
  DraftkingsWhite: `${COLOR.WHITE}, ${COLOR.ORANGE}`,
  FanduelWhite: `${COLOR.WHITE}, ${COLOR.FD_GREEN}`,
  YahooWhite: `${COLOR.WHITE}, ${COLOR.Y_PURPLE}`,
}
