// @ts-nocheck
import baseballBanner from '../../../assets/images/baseball-colored-2.jpg'
import { BREAKPOINT, COLOR, SPACING } from '../../../styles/variables'
import { css } from 'styled-components'

export const cardElementStyles = css`
  border-radius: 3px;
  background-color: #fff !important;
  ${({ inline }) => (inline ? 'display: inline' : '')}
`

export const cardFormStyles = css`
  display: flex;

  button {
    padding: 0.5em 0.75em;
    background-color: ${COLOR.BLUE_DARK};
    color: ${COLOR.WHITE};
  }

  input {
    flex-grow: 1;
    max-width: 250px;
    padding: 0.5em 0.75em;
  }
`
export const priceStyles = css`
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
`

export const textStyles = css`
  display: inline-block;
  font-size: 14px;
  color: ${COLOR.GREY_DARK};
  margin-left: 10px;
`

export const modalCenterText = css`
  font-size: 14px;
  color: ${COLOR.WHITE};
  text-align: center;
`

export const modalIcon = css`
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    margin-right: 5px;
  }
`

export const headerStyles = css`
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background-color: ${COLOR.BLUE_DARK2};
`

export const checklistItemStyles = css`
  display: flex;
  line-height: 20px;
  margin: ${SPACING.Y} 0;

  .checklist-icon {
    flex-shrink: 1;
    ${({ checked }) => !checked && 'visibility: hidden'}
  }

  .checklist-text {
    padding-left: ${SPACING.X};

    &.bolder {
      font-size: 1.2em;
      font-weight: 600;
    }
  }
`

export const bannerStyles = css`
  background-image: url(${baseballBanner});
  background-color: ${COLOR.BLUE_DARK2};
  background-size: cover;
  color: ${COLOR.WHITE};
  box-sizing: border-box;
  min-height: 250px;
  padding: 10px 10%;
  width: 100%;

  h1 {
    margin-bottom: 30px;
    text-align: center;
  }

  h2 {
    font-size: 1.4em;
    margin-bottom: 30px;
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  .perks {
    display: flex;
    flex-wrap: wrap;

    .perks-description {
      margin-bottom: 20px;
      width: 100%;
      font-size: 1.2em;
      font-weight: 300;
      text-align: center;

      .perks-count-bold {
        display: inline-block;
        font-size: 1.25em;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1.15em;
    }

    .perks {
      .perks-description {
        .perks-count-bold {
          font-size: 1.05em;
        }
      }
    }
  }
`

export const containerStyles = css`
  background-color: ${COLOR.WHITE};
  padding: 10px 20px;

  .join-button {
    margin: 10px 0;
    text-align: center;
    width: 100%;
  }

  .container-images {
    display: flex;
    flex-wrap: wrap;

    .container-image {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 33%;

      img {
        vertical-align: top;
        margin: 0 auto;
        max-height: 500px;
        max-width: 300px;
        border: 0;
      }
    }
  }

  .tiles {
    justify-content: center !important;
  }

  .model-preview {
    margin-top: 20px;

    .model-gif {
      margin: 0 auto;
      max-width: 500px;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    .container-images {
      .container-image {
        width: 100%;
      }
    }
  }
`
