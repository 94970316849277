import styled from 'styled-components'
import { containerStyles } from './loadingSpinnerStyles'

export const testIdLoadingSpinner = 'loading-spinner'
const LoadingContainer = styled.div`
  ${containerStyles}
`

const LoadingSpinner = ({
  image,
  styles,
  text,
  textMarginTop,
}: {
  image?: any
  styles?: Record<string, number | string>
  text?: string
  textMarginTop?: string
}) => (
  <LoadingContainer data-testid={testIdLoadingSpinner} style={styles}>
    <div className="lds-image">
      {image || (
        <div className="lds-grid">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      <div style={{ marginTop: textMarginTop }}>
        {`${text || 'Loading Data'}`}
      </div>
    </div>
  </LoadingContainer>
)

export default LoadingSpinner
