import './app.scss'
import './axiosConfig'
import AccountProvider, { AccountContext } from './AccountProvider'
import CacheBuster from 'react-cache-buster'
import packageInfo from '../../package.json'
import { AxiosProvider } from './AxiosContext'
import { ReactElement, ReactNode, lazy, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  RouteData,
  adminRouteData,
  standardRouteData,
  subscriberRouteData,
  userRouteData,
} from './routeData'

const AdminRoute = lazy(() => import('./components/application/AdminRoute'))
const MetaTags = lazy(() => import('./components/common/meta-tags/MetaTags'))
const Footer = lazy(() => import('./components/application/footer/Footer'))
const Header = lazy(() => import('./components/application/header/Header'))
const LoadingFta = lazy(() => import('./LoadingFta'))
const MlbDraftGuideBanner = lazy(
  () => import('./components/routes/mlb-pages/MlbDraftGuideBanner')
)
const NflDraftGuideBanner = lazy(
  () => import('./components/routes/nfl-pages/NflDraftGuideBanner')
)
const NoMatch = lazy(() => import('./components/application/no-match/NoMatch'))
const ResetPassword = lazy(() => import('./components/routes/ResetPassword'))
const SubHeader = lazy(
  () => import('./components/application/sub-header/SubHeader')
)
const SubscriberRoute = lazy(
  () => import('./components/application/SubscriberRoute')
)
const UserRoute = lazy(() => import('./components/application/UserRoute'))
const VerifyEmail = lazy(() => import('./components/routes/VerifyEmail'))
const WelcomeToFta = lazy(() => import('./components/routes/home/WelcomeToFta'))

const ENV = process.env.NODE_ENV
const version = packageInfo.version
if (ENV === 'production') console.log(`FTA Version: ${version}`)
const generateRoute = (routeData: RouteData, WrapperElem?: ReactElement) => {
  const { Elem, description, image, keywords, path, title } = routeData

  const baseElement = (
    <Route
      element={
        <>
          <MetaTags
            description={description}
            image={image}
            keywords={keywords}
            link={path}
            title={title}
          />
          {Elem}
        </>
      }
      key={path}
      path={path}
    />
  )

  const rendered = WrapperElem ? (
    <Route element={WrapperElem} key={path} path={path}>
      {baseElement}
    </Route>
  ) : (
    baseElement
  )

  return rendered
}

const App = () => {
  const AppWrapper = ({ children }: { children: ReactNode }) => {
    if (ENV !== 'production') return <>{children}</>

    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<LoadingFta />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory="."
      >
        {children}
      </CacheBuster>
    )
  }
  return (
    <AppWrapper>
      <AccountProvider>
        <AxiosProvider>
          <FullApp />
        </AxiosProvider>
      </AccountProvider>
    </AppWrapper>
  )
}

const FullApp = () => {
  const account = useContext(AccountContext)

  const { isLoading } = account
  if (isLoading) return <LoadingFta />

  return (
    <>
      <Header />
      <SubHeader />
      <MlbDraftGuideBanner />
      <NflDraftGuideBanner />
      <Routes>
        {standardRouteData.map((d) => generateRoute(d))}
        {userRouteData.map((d) => generateRoute(d, <UserRoute />))}
        {subscriberRouteData.map((d) =>
          generateRoute(d, <SubscriberRoute data={d} sport={d.sport} />)
        )}
        {adminRouteData.map((d) =>
          generateRoute(d, <AdminRoute sport={d.sport} />)
        )}
        <Route element={<VerifyEmail />} path="/verify-email/:emailToken" />
        <Route
          element={<ResetPassword />}
          path="/reset-password/:resetPasswordToken"
        />
        <Route element={<WelcomeToFta />} path="/welcome" />
        <Route element={<NoMatch />} path="*" />
      </Routes>
      <Footer />
    </>
  )
}

export default App
