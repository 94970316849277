// @ts-nocheck
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { COLOR } from '../../../styles/variables'
import { VARIANTS } from '../../../utils/constants'
import { styled } from '@mui/material/styles'

const ColorButton = ({
  backgroundColor,
  buttonColor,
  color,
  dataTestId,
  disabled,
  endIcon,
  fontSize,
  hoverBackgroundColor,
  hoverColor,
  link,
  margin,
  onClick,
  padding,
  selected,
  size,
  startIcon,
  style,
  text,
  type,
  variant,
  width,
  zIndex,
}) => {
  const defaultColor = variant === VARIANTS.TEXT ? 'inherit' : COLOR.GREY
  const defaultBackgroundColor =
    variant === VARIANTS.TEXT ? 'inherit' : COLOR.WHITE

  let textColor = defaultColor
  let buttonBackgroundColor = defaultBackgroundColor

  if (color) textColor = color
  if (backgroundColor) buttonBackgroundColor = backgroundColor

  if (selected)
    [textColor, buttonBackgroundColor] = [buttonBackgroundColor, textColor]
  if (buttonColor) {
    textColor = undefined
    buttonBackgroundColor = undefined
  }

  const StyledButton = styled(Button)(() => ({
    color: textColor,
    backgroundColor: buttonBackgroundColor,
    cursor: 'pointer',
    fontSize: fontSize || undefined,
    margin,
    padding,
    textTransform: 'none',
    minWidth: width || undefined,
    zIndex: zIndex || 0,
    '&:hover': {
      backgroundColor: hoverBackgroundColor || textColor,
      color: hoverColor || buttonBackgroundColor,
    },
    '&.Mui-disabled': {
      backgroundColor: buttonBackgroundColor,
      color: textColor,
    },
  }))

  return (
    <StyledButton
      color={buttonColor}
      data-testid={dataTestId}
      disabled={disabled}
      endIcon={endIcon}
      href={link}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      style={style}
      type={type || 'button'}
      variant={variant}
    >
      {text || ''}
    </StyledButton>
  )
}

ColorButton.propTypes = {
  backgroundColor: PropTypes.string,
  buttonColor: PropTypes.string, // Default material UI color
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fontSize: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  hoverColor: PropTypes.string,
  link: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  selected: PropTypes.bool,
  size: PropTypes.string,
  startIcon: PropTypes.node,
  style: PropTypes.object,
  text: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
  width: PropTypes.string,
  zIndex: PropTypes.number,
}

export default ColorButton
