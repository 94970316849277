import LineupOptimizer from '../../../assets/images/LineupOptimizer.png'
import MlbOptimizer from '../../../assets/images/MlbOptimizer.png'
import ReactGA from 'react-ga4'
import USER_ACTIONS from '../../../utils/constantExports/userActions'
import asyncLoadPaypal from '../../../utils/helperFunctions/asyncLoadPaypal'
import axios from 'axios'
import dk100 from '../../../assets/images/Draftkings100k.png'
import dk50 from '../../../assets/images/Draftkings50k.png'
import fd100 from '../../../assets/images/Fanduel100k.png'
import pure from '../../../styles/pure.module.scss'
import styled from 'styled-components'
import { AccountContext } from '../../../AccountProvider'
import {
  BUTTON_STATES,
  MUI_SIZES,
  ROUTES,
  SIGN_UP,
  THIS_SEASON,
  VARIANTS,
} from '../../../utils/constants'
import { COLOR } from '../../../styles/variables'
import { FlashOnIcon, SportsBasketballIcon } from '../../common/icons'
import { Fragment, lazy, useContext, useEffect, useState } from 'react'
import { MEMBERSHIP_OPTIONS, paypalPlans } from './constants'
import {
  bannerStyles,
  containerStyles,
  headerStyles,
  priceStyles,
  textStyles,
} from './membershipsStyles'
import { parseResponse } from '../../../utils/helpers'

const CheckoutModal = lazy(() => import('./CheckoutModal'))
const ColorButton = lazy(() => import('../../common/buttons/ColorButton'))
const ContentTile = lazy(() => import('../../common/content-tile/ContentTile'))
const Image = lazy(() => import('../../common/image/Image'))
const LoadingFtaContent = lazy(() => import('../../../LoadingFtaContent'))
const MembershipsChecklist = lazy(() => import('./MembershipsChecklist'))
const Spacer = lazy(() => import('../../common/spacer/Spacer'))
const Tile = lazy(() => import('../../common/tile/Tile'))

export const testIdMembershipsPage = 'membershipsPage'
export const testIdUserCount = 'userCount'
export const testIdMembershipCreateAccount = 'membershipCreateAccount'
export const testIdMembershipSignUp = 'membershipSignUp'
export const testIdMembershipCheckout = 'membershipCheckout'

const PriceStyled = styled.div`
  ${priceStyles}
`
const TextStyled = styled.div`
  ${textStyles}
`
const HeaderStyled = styled.div`
  ${headerStyles}
`
const ContainerStyled = styled.div`
  ${containerStyles}
`
const BannerStyled = styled.div`
  ${bannerStyles}
`

// other page means a page that is not the memberships page
const Memberships = ({ otherPage }: { otherPage?: boolean }) => {
  const userAccount = useContext(AccountContext)
  const [paypalPlanId, setPaypalPlanId] = useState('')
  const [plan, setPlan] = useState('')
  const [users, setUsers] = useState(null)

  useEffect(() => {
    const getUserCount = async () => {
      const users = await axios
        .get(ROUTES.USER_COUNT)
        .then((res) => parseResponse(res))
        .then((res) => res?.users)

      setUsers(users)
    }

    asyncLoadPaypal()
    getUserCount()
  }, [])

  const openModal = (plan: string, paypalPlanId: string) => {
    setPlan(plan)
    setPaypalPlanId(paypalPlanId)
  }

  const handleCloseModal = () => {
    setPlan('')
    setPaypalPlanId('')
  }

  const defaultChecklist = [
    {
      label: 'Lineup optimizer',
      checked: true,
    },
    {
      label: 'Ownership projections',
      checked: true,
    },
    {
      label: 'Live chat',
      checked: true,
    },
    {
      label: 'Draft guide access',
      checked: true,
    },
  ]

  const renderMembershipTile = (
    { level, membershipExpires, paypalPlanId, plan, price }: any,
    isAlreadySubscribed: boolean
  ) => {
    const { user } = userAccount

    let id = ''

    if (user) id = user.id
    const isMonthly = plan === paypalPlans.monthly
    const isWeekly = plan === paypalPlans.weekly
    const isAnnual = plan === paypalPlans.annual
    const checklist = [
      {
        label: 'Most popular',
        checked: isMonthly,
      },
      {
        label: 'Best price',
        checked: isAnnual,
      },
      {
        label: 'Weekly payments',
        checked: isWeekly,
      },
    ]

    if (isMonthly) {
      checklist.push({
        label: 'Save $10 a month',
        checked: true,
      })
    }

    if (isWeekly) {
      checklist.push({
        label: 'Cancel anytime',
        checked: true,
      })
    }

    if (isAnnual) {
      checklist.push({
        label: 'Save $60 a year',
        checked: true,
      })
    }

    return (
      <div key={level} style={{ display: 'flex' }}>
        <Spacer flex responsive>
          <Tile flexColumn grow width="300px">
            <HeaderStyled
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: `${level} Memberships Header ${
                    user ? user.username : ''
                  }`,
                })
                if (id) openModal(plan, paypalPlanId)
                else userAccount.setActiveModal(USER_ACTIONS.signup)
              }}
            >
              <Spacer>{level}</Spacer>
            </HeaderStyled>
            <Spacer noY>
              <PriceStyled>
                {price}
                <TextStyled>{membershipExpires}</TextStyled>
              </PriceStyled>
            </Spacer>
            <Spacer noY>
              <MembershipsChecklist checklist={checklist} />
            </Spacer>
            {id && !isAlreadySubscribed && (
              <Spacer marginTopAuto>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <ColorButton
                    buttonColor={BUTTON_STATES.SUCCESS}
                    dataTestId={testIdMembershipCheckout}
                    onClick={() => openModal(plan, paypalPlanId)}
                    size={MUI_SIZES.LARGE}
                    text="Checkout"
                    variant={VARIANTS.CONTAINED}
                  />
                </div>
              </Spacer>
            )}
            {!id && (
              <Spacer marginTopAuto>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <ColorButton
                    buttonColor={BUTTON_STATES.SUCCESS}
                    dataTestId={testIdMembershipSignUp}
                    onClick={() =>
                      userAccount.setActiveModal(USER_ACTIONS.signup)
                    }
                    text={SIGN_UP}
                    variant={VARIANTS.CONTAINED}
                  />
                </div>
              </Spacer>
            )}
          </Tile>
        </Spacer>
      </div>
    )
  }

  const { isSubscriber, user } = userAccount

  const id = (user && user.id) || null

  const isAlreadySubscribed = isSubscriber || (user && user.isSubscriber)

  const headline = 'Ready To Start Winning?'
  const tagline = 'Get the edge you need to dominate the competition'

  if (userAccount.isLoading || !users)
    return <LoadingFtaContent text="Loading membership data" />

  return (
    <div data-testid={testIdMembershipsPage}>
      <BannerStyled>
        <h1>{headline}</h1>
        <div className="perks">
          <div className="perks-description">
            {`Celebrating ${THIS_SEASON - 2011} years in business`}
          </div>
          <div className="perks-description">
            All sports included at one low price
          </div>
        </div>
        <h2>{tagline}</h2>
        <div className="perks">
          <div className="perks-description">
            <div className="perks-count-bold" data-testid={testIdUserCount}>
              {users}
            </div>{' '}
            users have already signed up. Join the community today
          </div>
        </div>
      </BannerStyled>
      <ContainerStyled>
        {!id && (
          <div className="join-button">
            <ColorButton
              backgroundColor={COLOR.BLUE_DARK2}
              color={COLOR.WHITE}
              dataTestId={testIdMembershipCreateAccount}
              onClick={() => userAccount?.setActiveModal(USER_ACTIONS.signup)}
              text="Create your free account"
              variant={VARIANTS.CONTAINED}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            marginBottom: '1rem',
          }}
        >
          {Object.keys(MEMBERSHIP_OPTIONS).map((i) => {
            const item = MEMBERSHIP_OPTIONS[i]
            return renderMembershipTile(item, isAlreadySubscribed)
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tile width="320px">
            <HeaderStyled>
              <Spacer>All plans include</Spacer>
            </HeaderStyled>

            <Spacer noY>
              <MembershipsChecklist checklist={defaultChecklist} />
            </Spacer>
          </Tile>
        </div>

        <div className="model-preview">
          <ContentTile
            backgroundColor={COLOR.WHITE}
            content={
              <Fragment>
                <Spacer>
                  <div className="model-gif">
                    <Image
                      alt="DFS player data preview"
                      caption="Using our real time DFS models and projections you can quickly and easily access the data you need"
                      src={LineupOptimizer}
                    />
                  </div>
                </Spacer>
                <Spacer>
                  Whether you need a Draftkings lineup generator, Fanduel lineup
                  optimizer or Yahoo DFS lineup optimizer, FTA+ has you covered.
                  Generate hundreds of lineups and export easily via CSV to
                  upload to the daily fantasy sports site of your choosing
                </Spacer>
              </Fragment>
            }
            fontColor={COLOR.GREY_DARK}
            header="Real time analytics combined with DFS data"
            icon={() => (
              <SportsBasketballIcon
                style={{ color: COLOR.BLUE_DARK, fontSize: '5em' }}
              />
            )}
            textAlign="center"
          />
        </div>

        <div className="model-preview">
          <ContentTile
            content={
              <Fragment>
                <Spacer>
                  <div className="model-gif">
                    <Image
                      alt="DFS lineup optimizer preview"
                      caption="FTA+ DFS projections coupled with our custom lineup optimizer can help you generate hundreds of lineups in minutes"
                      src={MlbOptimizer}
                    />
                  </div>
                </Spacer>
                <Spacer>
                  Log in to our FTA+ models to get updated DFS data while you
                  update your projections for your favorite site between
                  Draftkings, Fanduel and Yahoo. Then tweak the filters to get
                  your player pool just right and hit generate to fire up our
                  lightning fast lineup optimizer
                </Spacer>
              </Fragment>
            }
            fontColor={COLOR.WHITE}
            gradientOne
            header="Dominate the DFS competition with our lineup generator"
            icon={() => (
              <FlashOnIcon
                style={{ color: COLOR.BLUE_DARK, fontSize: '5em' }}
              />
            )}
            textAlign="center"
          />
        </div>

        {!otherPage && (
          <div className={pure['u-1']}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <ContentTile
                backgroundColor={COLOR.BLUE_DARK2}
                fontColor="#fff"
                header="Big paydays from Draftkings lineups and Fanduel lineups posted in our community"
              />
            </div>
            <div className="container-images">
              <div className="container-image">
                <img alt="Draftkings lineup that won $100,000" src={dk100} />
              </div>
              <div className="container-image">
                <img alt="Draftkings lineup that won $50,000" src={dk50} />
              </div>
              <div className="container-image">
                <img alt="Fanduel lineup that won $100,000" src={fd100} />
              </div>
            </div>
          </div>
        )}
      </ContainerStyled>
      {plan && paypalPlanId && (
        <CheckoutModal
          onRequestClose={handleCloseModal}
          paypalPlanId={paypalPlanId}
          plan={plan}
        />
      )}
    </div>
  )
}

export default Memberships
