import MlbIrt from './components/routes/mlb-pages/MlbIrt'
import { MetaTagsProps } from './components/common/meta-tags/MetaTags'
import { ReactElement, lazy } from 'react'
import {
  SIGN_UP,
  SPORT,
  THIS_SEASON,
  TODAY_PRETTY_DATE,
} from './utils/constants'
import { SITE_LINKS } from './utils/links'

const Admin = lazy(() => import('./components/routes/admin/Admin'))
const AdminRankings = lazy(
  () => import('./components/nfl/draftCrusher/admin/AdminRankings')
)
const Chat = lazy(() => import('./components/routes/chat/Chat'))
const ContactUs = lazy(() => import('./components/common/contact-us/ContactUs'))
const DataHoc = lazy(() => import('./components/data/DataHoc'))
const DiscordAuth = lazy(
  () => import('./components/common/discord-auth/DiscordAuth')
)
const DraftCrusherGateway = lazy(
  () =>
    import('./components/nfl/draftCrusher/user/userDraft/DraftCrusherGateway')
)
const FfTeamNames = lazy(
  () => import('./components/nfl/ff-team-names/FfTeamNames')
)
const FtaEmailEditor = lazy(
  () => import('./components/common/editor/FtaEmailEditor')
)
const Home = lazy(() => import('./components/routes/home/Home'))
const ManageEmail = lazy(() => import('./components/routes/user/ManageEmail'))
const Memberships = lazy(
  () => import('./components/routes/memberships/Memberships')
)
const MlbCsw = lazy(() => import('./components/routes/mlb-pages/MlbCsw'))
const MlbDraftGuide = lazy(
  () => import('./components/routes/mlb-pages/MlbDraftGuide')
)
const MlbHome = lazy(() => import('./components/routes/mlb-pages/MlbHome'))
const MlbPitcherSplits = lazy(
  () => import('./components/routes/mlb-pages/MlbPitcherSplits')
)
const MlbProbableStarters = lazy(
  () => import('./components/routes/mlb-pages/MlbProbableStarters')
)
const MlbStartingLineups = lazy(
  () => import('./components/routes/mlb-pages/MlbStartingLineups')
)
const MmaHome = lazy(() => import('./components/routes/mma-home/MmaHome'))
const NascarHome = lazy(
  () => import('./components/routes/nascar-pages/NascarHome')
)
const NascarStartPositions = lazy(
  () => import('./components/routes/nascar-pages/NascarStartPositions')
)
const NbaDvp = lazy(() => import('./components/routes/nba-pages/NbaDvp'))
const NbaEnvironmentsPage = lazy(
  () => import('./components/routes/nba-pages/NbaEnvironmentsPage')
)
const NbaHome = lazy(() => import('./components/routes/home/NbaHome'))
const NflDraftGuide = lazy(
  () => import('./components/routes/nfl-pages/NflDraftGuide')
)
const NflHome = lazy(() => import('./components/routes/home/NflHome'))
const NflMockLanding = lazy(
  () => import('./components/nfl/draftCrusher/user/mockDrafts/NflMockLanding')
)
const NflRbMatrix = lazy(
  () => import('./components/routes/nfl-pages/NflRbMatrix')
)
const NhlHome = lazy(() => import('./components/routes/home/NhlHome'))
const NhlStartingLineups = lazy(
  () => import('./components/routes/nhl-pages/NhlStartingLineups')
)
const Ownership = lazy(() => import('./components/routes/ownership/Ownership'))
const PgaHome = lazy(() => import('./components/routes/pga-pages/PgaHome'))
const PrivacyPolicy = lazy(
  () => import('./components/common/privacy-policy/PrivacyPolicy')
)
const QuickMock = lazy(
  () => import('./components/nfl/draftCrusher/user/mockDrafts/QuickMock')
)
const RedditAuth = lazy(() => import('./components/common/RedditAuth'))
const TermsOfService = lazy(
  () => import('./components/common/terms-of-service/TermsOfService')
)
const User = lazy(() => import('./components/routes/user/User'))
const WelcomeToFtaPlus = lazy(
  () => import('./components/routes/home/WelcomeToFtaPlus')
)

export interface RouteData extends MetaTagsProps {
  path: string
  sport?: string
  Elem: ReactElement
}

const mlbImage = './assets/images/baseball-colored-2.jpg'

// Standard open routes
export const standardRouteData: Array<RouteData> = [
  {
    Elem: <Home />,
    description:
      'Fantasy Team Advice is your home for DFS draft & lineup news. We offer optimizer guides & projections for NBA, NFL, NHL, MLB & more! Join our fantasy sports community today!',
    keywords:
      'DFS, Fanduel, Draftkings, Yahoo, NFL, MLB, NBA, NHL, PGA, NASCAR, Optimizer, Fantasy Sports',
    path: SITE_LINKS.HOME,
    title: 'Fantasy Advice | DFS Draft & Lineup Optimizer | Sports Guide',
  },
  {
    Elem: <MlbHome />,
    description:
      'Major League Baseball Fantasy Team Advice. MLB DFS Draft lineup optimizer and suggestions. Optimize your lineup with us today!',
    image: mlbImage,
    path: SITE_LINKS.HOME_PAGES[SPORT.MLB],
    title: 'MLB Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <MmaHome />,
    description:
      'Mixed Martial Arts Fantasy Advice. MMA DFS lineup optimizer and suggestions. Optimize your Draftkings lineup with us today!',
    path: SITE_LINKS.HOME_PAGES[SPORT.MMA],
    title: 'MMA Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <NascarHome />,
    description:
      'NASCAR Fantasy Advice. Nascar DFS lineup optimizer and suggestions. Optimize your Draftkings or Fanduel lineup with us today!',
    path: SITE_LINKS.HOME_PAGES[SPORT.NASCAR],
    title: 'NASCAR Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <NhlHome />,
    description:
      'Hockey Fantasy Team Advice. NHL DFS Draft lineup optimizer and suggestions. Optimize your lineup with us today!',
    path: SITE_LINKS.HOME_PAGES[SPORT.NHL],
    title: 'NHL Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <PgaHome />,
    description:
      'Professional Golf Fantasy Advice. PGA DFS lineup optimizer and suggestions. Optimize your lineup with us today!',
    path: SITE_LINKS.HOME_PAGES[SPORT.PGA],
    title: 'PGA Golf Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <NflHome />,
    description:
      'Football Fantasy Team Advice. NFL DFS Draft lineup optimizer and suggestions. Optimize your lineup with us today!',
    path: SITE_LINKS.HOME_PAGES[SPORT.NFL],
    title: 'NFL Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <NbaHome />,
    description:
      'Basketball Fantasy Team Advice. NBA DFS Draft lineup optimizer and suggestions. Optimize your lineup with us today!',
    path: SITE_LINKS.HOME_PAGES[SPORT.NBA],
    title: 'NBA Fantasy Advice | DFS Draft & Lineup Optimizer Guide',
  },
  {
    Elem: <MlbCsw />,
    description: `Starting pitcher CSW for MLB. ${THIS_SEASON} major league baseball data for DFS MLB players on Draftkings or Fanduel. Subscribe today!`,
    image: mlbImage,
    path: SITE_LINKS.MLB_CSW,
    title: `MLB Starting Pitcher CSW data |  ${THIS_SEASON} Major League Baseball`,
  },
  {
    Elem: <MlbPitcherSplits />,
    description: `Breaking down pitcher splits by team to help with stacking for DFS. Updated daily for the ${THIS_SEASON} major league baseball season . Subscribe today!`,
    image: mlbImage,
    path: SITE_LINKS.MLB_PITCHER_SPLITS,
    title: `MLB Pitcher Splits |  ${THIS_SEASON} DFS top teams to target`,
  },
  {
    Elem: <FfTeamNames />,
    description: `Discover the best fantasy football team names of ${THIS_SEASON}. Stand out in your league with our unique, funny, and witty team name suggestions tailored for serious NFL fans.`,
    image: './assets/images/Names.png',
    keywords: `${THIS_SEASON} fantasy football team names, best fantasy football names, upvoted fantasy football team names, funny NFL team names, top fantasy football names, creative fantasy football team names, ${THIS_SEASON} fantasy names`,
    path: SITE_LINKS.FF_TEAM_NAMES,
    title: `${THIS_SEASON} Fantasy Football Team Names | Best Creative & Funny | Most Upvoted`,
  },
  {
    Elem: <NflRbMatrix />,
    description:
      'Fantasy football running back matrix highlight the best and worst matchups of the week for DFS NFL and season long players. View it free today!',
    path: SITE_LINKS.NFL_RB_MATRIX,
    title: 'NFL Running Back Matchup Matrix',
  },
  {
    Elem: <NflMockLanding />,
    description: `${THIS_SEASON} fantasy football mock draft lobby.  Customize your NFL rankings, quickly mock draft your fantasy football teams to practice for live drafts. Get started free today!`,
    path: SITE_LINKS.NFL_MOCK_DRAFT,
    title: `Fantasy Football Mock Draft ${THIS_SEASON}`,
  },
  {
    Elem: <ManageEmail />,
    description: 'Manage FTA email preferences',
    path: SITE_LINKS.EMAIL_UNSUBSCRIBE,
    title: 'Unsubscribe',
  },
  {
    Elem: <ContactUs />,
    description: 'How to contact fantasy team advice team members',
    path: SITE_LINKS.CONTACT_US,
    title: 'Contact us | Help with DFS Optimizer or Ownership',
  },
  {
    Elem: <Memberships />,
    description: `Best DFS Memberships packages for Draftkings Fanduel and Yahoo. Low cost options that are cheap and affordable.  Monthly, weekly, daily and annual rates available. ${SIGN_UP} today!`,
    path: SITE_LINKS.MEMBERSHIPS,
    title:
      'Best DFS Memberships | Fantasy Advice | Cheap and Affordable Lineup Optimizer',
  },
  {
    Elem: <PrivacyPolicy />,
    description:
      'Fantasy Team Advice privacy policy. How we manage user data while on our site',
    path: SITE_LINKS.PRIVACY_POLICY,
    title: 'Privacy Policy for FantasyTeamAdvice',
  },
  {
    Elem: <TermsOfService />,
    description:
      'Outlining the terms of service to use FantasyTeamAdvice and engage with our DFS community',
    path: SITE_LINKS.TERMS_OF_SERVICE,
    title: 'Terms of Service',
  },
  {
    Elem: <MlbDraftGuide />,
    description: `Fantasy baseball draft rankings for ${THIS_SEASON} season long MLB drafts. Top 500+ players ranked by position and overall`,
    image: mlbImage,
    path: SITE_LINKS.MLB_RANKINGS,
    title: `${THIS_SEASON} Fantasy Baseball Draft Guide Rankings | Season long 5x5 auction values`,
  },
  {
    Elem: <NflDraftGuide />,
    description: `${THIS_SEASON} fantasy football rankings / software / cheat sheet for draft day. Updated daily supports auction, points per first down (PPFD), PPR, half PPR, standard, keeper, third round reversal or TE Premium. Buy it now!`,
    path: SITE_LINKS.NFL_RANKINGS,
    title: `Fantasy Football Cheat Sheets ${THIS_SEASON} | Draft Rankings`,
  },
  {
    Elem: <Ownership sport={SPORT.MLB} />,
    description: `Best free ${THIS_SEASON} DFS MLB ownership projections for Draftkings / Fanduel. If you play daily fantasy baseball, join FTA+ for updated Draftkings / Fanduel ownerships today!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.MLB],
    title: `Free ${THIS_SEASON} MLB DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <Ownership sport={SPORT.MMA} />,
    description: `Best ${THIS_SEASON} DFS MMA ownership projections for Draftkings provided free. Ready to set DFS MMA lineups? Join FTA+ for advanced MMA analytics today!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.MMA],
    title: `Free MMA DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <Ownership sport={SPORT.NASCAR} />,
    description: `Best ${THIS_SEASON} NASCAR DFS ownership projections for Draftkings / Fanduel provided free. Get updated projected ownership for each driver on Draftkings / Fanduel today!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.NASCAR],
    title: `Free ${THIS_SEASON} NASCAR DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <Ownership sport={SPORT.PGA} />,
    description: `Best ${THIS_SEASON} PGA DFS ownership projections for Draftkings / Fanduel provided free. Get updated projected ownership for each golfer on Draftkings / Fanduel today!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.PGA],
    title: `Free ${THIS_SEASON} PGA DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <Ownership sport={SPORT.NBA} />,
    description: `Best free ${THIS_SEASON} DFS NBA ownership projections for Draftkings / Fanduel. If you play daily fantasy basketball. ${SIGN_UP} for live Draftkings / Fanduel ownership updates today!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.NBA],
    title: `Free ${THIS_SEASON} NBA DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <Ownership sport={SPORT.NFL} />,
    description: `Free weekly ${THIS_SEASON} DFS NFL ownership data and projections for Draftkings / Fanduel daily fantasy sports. Updating through Sunday, check them out now!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.NFL],
    title: `Free ${THIS_SEASON} NFL DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <Ownership sport={SPORT.NHL} />,
    description: `Free weekly ${THIS_SEASON} DFS NHL ownership data and projections for Draftkings / Fanduel daily fantasy sports. Updating through Sunday, check them out now!`,
    path: SITE_LINKS.OWNERSHIP[SPORT.NHL],
    title: `Free ${THIS_SEASON} NHL DFS Ownership Projections for Draftkings / Fanduel`,
  },
  {
    Elem: <NbaDvp />,
    description:
      'Daily updated NBA DvP data providing defense vs position stats for all 30 national basketball association teams',
    path: SITE_LINKS.NBA_DVP,
    title: 'DFS NBA DvP | Defense vs Position',
  },
  {
    Elem: <NbaEnvironmentsPage />,
    description:
      'NBA pace projections and game environments projections for Daily Fantasy Sports | DFS Draftkings Fanduel',
    path: SITE_LINKS.NBA_ENVIRONMENTS,
    title:
      'DFS NBA pace projections and game environments for Draftkings, Fanduel and Yahoo Daily',
  },
  {
    Elem: <MlbStartingLineups />,
    description: `Today's ${THIS_SEASON} MLB starting lineups, Vegas game totals, & odds alongside predicted weather for all major league baseball games`,
    path: SITE_LINKS.MLB_STARTING_LINEUPS,
    title: `${THIS_SEASON} MLB Starting Lineups Vegas Odds and Weather | DFS Draftkings, Fanduel and Yahoo`,
  },
  {
    Elem: <MlbIrt />,
    description: `MLB Implied Runs for each lineup today, based on vegas odds. ${TODAY_PRETTY_DATE} major league baseball data for DFS MLB players on Draftkings or Fanduel. Subscribe today!`,
    path: SITE_LINKS.MLB_IRT,
    title: `MLB Implied Runs Today | ${TODAY_PRETTY_DATE} Major League Baseball`,
  },
  {
    Elem: <MlbProbableStarters />,
    description: `${THIS_SEASON} Probable pitchers for MLB & daily projected starters today for all major league baseball games. Not sure which probable pitcher is starting each game? Find out with FTA!`,
    path: SITE_LINKS.MLB_PROBABLE,
    title: `${THIS_SEASON} MLB Probable Pitchers Today | Starters for each game`,
  },
  {
    Elem: <NhlStartingLineups />,
    description: `Today's ${THIS_SEASON} NHL starting lineups and goalies | Check the top line skaters, power play lines and projected goalies for each game`,
    path: SITE_LINKS.NHL_STARTING_LINEUPS,
    title: `${THIS_SEASON} NHL Starting Lineups And Goalies | DFS Draftkings, Fanduel and Yahoo`,
  },
  {
    Elem: <NascarStartPositions />,
    description: `${THIS_SEASON} NASCAR Starting Positions & Betting Odds. Get updated starting positions and betting lines before the race begins. Join FTA+ today!`,
    path: SITE_LINKS.NASCAR_POSITIONS,
    title: `${THIS_SEASON} NASCAR Starting Positions & Betting Odds | DFS race day guide`,
  },
]

// Routes that require a user account, but no subscription
export const userRouteData: Array<RouteData> = [
  {
    description:
      'Fantasy football rankings updated daily. The FTA draft crusher cheat sheet helps you dominate draft day with regular updates, auction pricing, first down projections and more. Buy it now!',
    path: SITE_LINKS.NFL_DRAFT_GUIDE,
    Elem: <DraftCrusherGateway />,
    title: 'Draft Crusher | Fantasy Football Rankings',
  },
  {
    Elem: <QuickMock />,
    description: `${THIS_SEASON} quick fantasy football mock drafts.  Draft against simulated users for fast reliable outcomes to see what your live draft results may look like!`,
    path: SITE_LINKS.NFL_CPU_MOCK,
    title: `Quick Mock Draft Software | Fantasy Football ${THIS_SEASON}`,
  },
  {
    Elem: <DiscordAuth />,
    description: `${THIS_SEASON} Discord authorization endpoint for FTA+`,
    path: SITE_LINKS.DISCORD_AUTH,
    title: `Authorize FTA+ and Discord`,
  },
  {
    Elem: <RedditAuth />,
    description: `${THIS_SEASON} Reddit authorization endpoint for FTA+`,
    path: SITE_LINKS.REDDIT_AUTH,
    title: `Authorize FTA+ and Reddit`,
  },
  {
    Elem: <User />,
    description: `FTA User Dasbhoard and Settings`,
    path: SITE_LINKS.USER,
    title: `User Dashboard`,
  },
]

// Subscriber only routes
export const subscriberRouteData: Array<RouteData> = [
  ...[
    SPORT.PGA,
    SPORT.MLB,
    SPORT.MMA,
    SPORT.NHL,
    SPORT.NBA,
    SPORT.NFL,
    SPORT.NASCAR,
  ].map((sport) => ({
    Elem: <DataHoc sport={sport} />,
    description: `${THIS_SEASON} DFS ${sport.toUpperCase()} Optimizer`,
    path: SITE_LINKS.OPTIMIZER[sport],
    sport,
    title: `${THIS_SEASON} DFS ${sport.toUpperCase()} Optimizer`,
  })),
  {
    Elem: <WelcomeToFtaPlus />,
    description: `${THIS_SEASON} FTA+ subscriber welcome landing page`,
    path: SITE_LINKS.FTA_PLUS_WELCOME,
    title: 'Welcome to FTA+',
  },
  {
    Elem: <Chat />,
    description: `${THIS_SEASON} FTA+ subscriber chat page`,
    path: SITE_LINKS.CHAT,
    title: 'FTA+ Discord Chat',
  },
]

// Admin only routes
export const adminRouteData: Array<RouteData> = [
  {
    Elem: <AdminRankings />,
    description: `${THIS_SEASON} Admin  ${SPORT.NFL.toUpperCase()} rankings`,
    path: SITE_LINKS.NFL_ADMIN_RANKINGS,
    sport: SPORT.NFL,
    title: `${THIS_SEASON} Admin  ${SPORT.NFL.toUpperCase()} rankings`,
  },
  {
    Elem: <FtaEmailEditor />,
    description: 'Email editor',
    path: SITE_LINKS.EMAIL_EDITOR,
    title: 'FTA Admin Email Editor',
  },
  {
    Elem: <Admin />,
    description: 'Admin page',
    path: SITE_LINKS.ADMIN,
    title: 'FTA Admin Page',
  },
]
