import { css } from 'styled-components'

export const notificationsStyles = css`
  display: flex;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  right: 10px;
  margin-right: 10px;
  width: 300px;
  max-height: 100%;
  z-index: 10000;

  #fta-notifications {
    align-items: flex-end;
    display: flex;
    flex-grow: 1;
    max-height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;

    div {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      flex: 0 0 100%;
      font-size: 1.2em;
      font-weight: 300;
      margin-bottom: 5px;
      margin-right: 10px;
      padding: 10px;
      z-index: 10;
    }
  }
`
