import { SITE_LINKS } from '../links'
import { SPORT } from '../constants'

const MENU_PAGES: Record<string, any> = {
  NBA: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.NBA],
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.NBA],
    'Pace Projections': '/dfs-nba-pace-projections-environments',
    DvP: '/dfs-nba-dvp-defense-vs-position',
  },
  NHL: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.NHL],
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.NHL],
    'Starting Lineups': '/todays-nhl-starting-lineups-goalies',
  },
  MLB: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.MLB],
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.MLB],
    'Starting Lineups': '/mlb-starting-lineups-tonight',
    'Probable Pitchers': '/mlb-probable-starters-starting-pitchers-today',
    'CSW Chart': SITE_LINKS.MLB_CSW,
    'Pitcher Splits': SITE_LINKS.MLB_PITCHER_SPLITS,
    'Season Long Draft Guide': SITE_LINKS.MLB_RANKINGS,
    'MLB Implied Runs': SITE_LINKS.MLB_IRT,
  },
  MMA: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.MMA],
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.MMA],
  },
  NASCAR: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.NASCAR],
    'Starting Positions': '/nascar-starting-positions-betting-odds',
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.NASCAR],
  },
  NFL: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.NFL],
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.NFL],
    'Running Back Matchups': SITE_LINKS.NFL_RB_MATRIX,
    'Mock Draft Lobby': SITE_LINKS.NFL_MOCK_DRAFT,
    'Draft Crusher': SITE_LINKS.NFL_RANKINGS,
  },
  PGA: {
    Optimizer: SITE_LINKS.OPTIMIZER[SPORT.PGA],
    'Ownership Projections': SITE_LINKS.OWNERSHIP[SPORT.PGA],
  },
}

export default MENU_PAGES
