import { BREAKPOINT, COLOR } from '../../../styles/variables'
import { css } from 'styled-components'

export const HeaderStyles = css`
  background-color: ${COLOR.BLUE_DARK2};

  .inner-header {
    height: 30px;

    .logo-wrapper {
      display: inline-block;
      height: 30px;

      img {
        height: 100%;
      }
    }

    .mobile-menu {
      display: none;
    }

    .user-menu {
      float: right;
      display: flex;

      button {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    border-bottom: 1px solid ${COLOR.WHITE};

    .inner-header {
      .mobile-menu {
        position: absolute;
        top: 10px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 135px;
        z-index: 10;
      }

      .user-menu {
        display: none;
      }
    }
  }
`
