// @ts-nocheck
import PropTypes from 'prop-types'
import styles from './spacer.module.scss'

const Spacer = ({
  children,
  fullWidth,
  noX,
  noY,
  noTop,
  noBottom,
  largeX,
  largeLeft,
  flex,
  largeRight,
  marginBottomAuto,
  largeY,
  responsive,
  smallY,
  marginTopAuto,
  noLeft,
  noRight,
}) => {
  let classNames = `${styles.spacer}`
  if (noX) classNames += ` ${styles['no-x']}`
  if (largeX) classNames += ` ${styles['large-x']}`
  if (smallY) classNames += ` ${styles['small-y']}`
  if (largeY) classNames += ` ${styles['large-y']}`
  if (noY) classNames += ` ${styles['no-y']}`
  if (noTop) classNames += ` ${styles['no-top']}`
  if (noBottom) classNames += ` ${styles['no-bottom']}`
  if (noRight) classNames += ` ${styles['no-right']}`
  if (noLeft) classNames += ` ${styles['no-left']}`
  if (flex) classNames += ` ${styles.flex}`
  if (fullWidth) classNames += ` ${styles['full-width']}`
  if (largeLeft) classNames += ` ${styles['large-left']}`
  if (largeRight) classNames += ` ${styles['large-right']}`
  if (responsive) classNames += ` ${styles.responsive}`
  if (marginTopAuto) classNames += ` ${styles['margin-top-auto']}`
  if (marginBottomAuto) classNames += ` ${styles['margin-bottom-auto']}`

  return <div className={classNames}>{children}</div>
}

Spacer.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.bool,
  fullWidth: PropTypes.bool,
  largeLeft: PropTypes.bool,
  largeRight: PropTypes.bool,
  largeX: PropTypes.bool,
  largeY: PropTypes.bool,
  marginBottomAuto: PropTypes.bool,
  marginTopAuto: PropTypes.bool,
  noBottom: PropTypes.bool,
  noLeft: PropTypes.bool,
  noRight: PropTypes.bool,
  noTop: PropTypes.bool,
  noX: PropTypes.bool,
  noY: PropTypes.bool,
  responsive: PropTypes.bool,
  smallY: PropTypes.bool,
}

export default Spacer
