export const paypalPlans: Record<string, string> = {
  daily: 'daily',
  annual: 'annual',
  weekly: 'weekly',
  monthly: 'monthly',
}

export const paypalPlanIds: Record<string, Record<string, string>> = {
  development: {
    [paypalPlans.daily]: 'P-4SU2509424419811ELWNHLNI',
    [paypalPlans.annual]: 'P-4SU2509424419811ELWNHLNI',
    [paypalPlans.weekly]: 'P-4SU2509424419811ELWNHLNI',
    [paypalPlans.monthly]: 'P-4SU2509424419811ELWNHLNI',
  },
  test: {
    [paypalPlans.daily]: 'P-4SU2509424419811ELWNHLNI',
    [paypalPlans.annual]: 'P-4SU2509424419811ELWNHLNI',
    [paypalPlans.weekly]: 'P-4SU2509424419811ELWNHLNI',
    [paypalPlans.monthly]: 'P-4SU2509424419811ELWNHLNI',
  },
  production: {
    [paypalPlans.daily]: 'P-07C325826E4532008L74WNHY',
    [paypalPlans.annual]: 'P-52C93664V7877243NL7RDIUA',
    [paypalPlans.weekly]: 'P-2PN35031250609528L7RDHWY',
    [paypalPlans.monthly]: 'P-7PP99575G0388494ML7RDHCY',
  },
}

export const paypalProductIds: Record<string, string> = {
  development: 'PROD-9Y825835MJ855221W',
  production: 'PROD-0AP88507V51807509',
}

export const MEMBERSHIP_OPTIONS: Record<string, any> = {
  monthly: {
    cost: 29.99,
    level: 'Monthly',
    membershipExpires: 'Renewing',
    paypalPlanId: paypalPlanIds[process.env.NODE_ENV!].monthly,
    plan: paypalPlans.monthly,
    price: '$29.99',
    shorthand: 'month',
  },
  weekly: {
    cost: 9.99,
    level: 'Weekly',
    membershipExpires: 'Renewing',
    paypalPlanId: paypalPlanIds[process.env.NODE_ENV!].weekly,
    plan: paypalPlans.weekly,
    price: '$9.99',
    shorthand: 'week',
  },
  annual: {
    cost: 299.99,
    level: 'Annual',
    membershipExpires: 'Renewing',
    paypalPlanId: paypalPlanIds[process.env.NODE_ENV!].annual,
    plan: paypalPlans.annual,
    price: '$299.99',
    shorthand: 'year',
  },
}
