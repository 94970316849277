import USER_ACTIONS from './userActions'
import { LOG_IN, SIGN_UP } from '../constants'

const MODAL_TITLES: Record<string, string> = {
  [USER_ACTIONS.emailVerificationRequired]: 'Email Requires Verification',
  [USER_ACTIONS.forgotUsernameEmailSent]: 'Email sent',
  [USER_ACTIONS.login]: LOG_IN,
  [USER_ACTIONS.passwordResetRequired]: 'Reset your password',
  [USER_ACTIONS.resetPasswordEmailSent]: 'Password reset email sent',
  [USER_ACTIONS.checkoutComplete]: 'Checkout Complete',
  [USER_ACTIONS.checkoutDraftGuideComplete]: 'Draft Guide Checkout Complete',
  [USER_ACTIONS.sendForgotUsernameEmail]: 'Forgot username?',
  [USER_ACTIONS.sendResetPasswordEmail]: 'Send password reset email',
  [USER_ACTIONS.signup]: SIGN_UP,
  [USER_ACTIONS.facebookSignup]: `${SIGN_UP} With Facebook`,
  [USER_ACTIONS.verificationEmailSent]: 'Verification Email Sent',
  [USER_ACTIONS.updateCardInfo]: 'Update Card Info',
}

export default MODAL_TITLES
