import { NFL_DRAFT_GUIDE_LINK, SPORT } from './constants'

export const SITE_LINKS = {
  ADMIN: '/admin',
  CHAT: '/chat',
  CONTACT_US: '/contact-fta',
  DISCORD_AUTH: '/discord-auth',
  EMAIL_EDITOR: '/create-new-email',
  REDDIT_AUTH: '/reddit-auth',
  USER: '/user',
  EMAIL_UNSUBSCRIBE: '/email-unsubscribe',
  HOME: '/',
  FF_TEAM_NAMES: '/best-funny-fantasy-football-team-names',
  NFL_DRAFT_GUIDE: NFL_DRAFT_GUIDE_LINK,
  MEMBERSHIPS: '/memberships',
  MLB_CSW: '/daily-fantasy-baseball/starting-pitcher-csw',
  MLB_IRT: 'daily-fantasy-baseball/mlb-implied-runs',
  MLB_PITCHER_SPLITS: '/daily-fantasy-baseball/pitcher-splits',
  MLB_RANKINGS: '/fantasy-baseball-draft-guide',
  NFL_ADMIN_RANKINGS: '/nfl-admin-rankings',
  NFL_CPU_MOCK: '/fantasy-football/automated-mock-draft-cpu',
  NFL_MOCK_DRAFT: '/fantasy-football/mock-draft',
  NFL_RANKINGS: '/fantasy-football-rankings-cheat-sheet',
  NFL_RANKINGS_REDDIT: '/fantasy-football-rankings-cheat-sheet?rff=reddit',
  NFL_RB_MATRIX: '/dfs/nfl/running-back-matchup-matrix',
  HOME_PAGES: {
    [SPORT.MLB]: '/dfs/mlb/home',
    [SPORT.MMA]: '/dfs/mma/home',
    [SPORT.NASCAR]: '/dfs/nascar/home',
    [SPORT.NBA]: '/dfs/nba/home',
    [SPORT.NFL]: '/dfs/nfl/home',
    [SPORT.NHL]: '/dfs/nhl/home',
    [SPORT.PGA]: '/dfs/pga/home',
  },
  OWNERSHIP: {
    [SPORT.MLB]: '/dfs/mlb/ownership',
    [SPORT.MMA]: '/dfs/mma/ownership',
    [SPORT.NASCAR]: '/dfs/nascar/ownership',
    [SPORT.NBA]: '/dfs/nba/ownership',
    [SPORT.NFL]: '/dfs/nfl/ownership',
    [SPORT.NHL]: '/dfs/nhl/ownership',
    [SPORT.PGA]: '/dfs/pga/ownership',
  },
  OPTIMIZER: {
    [SPORT.MLB]: '/dfs/mlb/optimizer',
    [SPORT.MMA]: '/dfs/mma/optimizer',
    [SPORT.NASCAR]: '/dfs/nascar/optimizer',
    [SPORT.NBA]: '/dfs/nba/optimizer',
    [SPORT.NFL]: '/dfs/nfl/optimizer',
    [SPORT.NHL]: '/dfs/nhl/optimizer',
    [SPORT.PGA]: '/dfs/pga/optimizer',
  },
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  MLB_PROBABLE: '/mlb-probable-starters-starting-pitchers-today',
  MLB_STARTING_LINEUPS: '/mlb-starting-lineups-tonight',
  NBA_DVP: '/dfs-nba-dvp-defense-vs-position',
  NBA_ENVIRONMENTS: '/dfs-nba-pace-projections-environments',
  NHL_STARTING_LINEUPS: '/todays-nhl-starting-lineups-goalies',
  NASCAR_POSITIONS: '/nascar-starting-positions-betting-odds',
  FTA_PLUS_WELCOME: '/welcome-to-fta-plus',
  FTA_WELCOME: '/welcome',
}
