import { BREAKPOINT, COLOR } from '../../../styles/variables'
import { css } from 'styled-components'

export const containerStyles = css`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  background-color: ${COLOR.BLACK};
  color: ${COLOR.WHITE};
  display: flex;
  font-size: 2em;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 1;
  z-index: 10;

  .lds-image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    width: 100%;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
    background-color: ${COLOR.BLUE_DODGER};
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    background-color: ${COLOR.WHITE};
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    background-color: ${COLOR.BLUE_DODGER};
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    background-color: ${COLOR.WHITE};
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    background-color: ${COLOR.BLUE_DODGER};
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    background-color: ${COLOR.WHITE};
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    background-color: ${COLOR.BLUE_DODGER};
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    background-color: ${COLOR.WHITE};
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    background-color: ${COLOR.BLUE_DODGER};
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.MEDIUM}) {
    font-size: 1.25rem;
  }
`
