import axios from 'axios'
import { parseError } from './utils/helpers'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Replace this with your error handling logic
    parseError(error)
    return Promise.reject(error)
  }
)
