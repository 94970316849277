// @ts-nocheck
import {
  FULL_SITE_NAME,
  IS_TEST_ENV,
  NOTIFICATIONS_KEY,
  SITES,
} from './constants'

export const testIdNotificationMessage = 'notificationMessage'

export const notifyFailure = (message) =>
  notifyUser({ status: 'FAILURE', message })

export const notifySuccess = (message) => {
  notifyUser({ status: 'SUCCESS', message })
}

export const notifyWarning = (message) =>
  notifyUser({ status: 'WARNING', message })

export const getFullSiteName = (site) => {
  if (site === SITES.DK) return FULL_SITE_NAME[SITES.DK]
  if (site === SITES.FD) return FULL_SITE_NAME[SITES.FD]

  return FULL_SITE_NAME[SITES.Y]
}

export const getRandomArrayEntry = (array) =>
  array[Math.floor(Math.random() * array.length)]

export const notifyUser = async (response) => {
  const isNotificationsEnabled = localStorage.getItem(NOTIFICATIONS_KEY)
  const { message, status } = response

  if (
    (!isNotificationsEnabled || isNotificationsEnabled !== 'true') &&
    status !== 'FAILURE'
  )
    return

  let backgroundColor = '#23C552'
  let color = 'white'
  let sleepTimer = 2000
  if (status === 'FAILURE') {
    backgroundColor = '#F84F31'
    sleepTimer = 3000
  }
  if (status === 'WARNING') {
    backgroundColor = '#FDDA16'
    color = 'black'
  }

  if (IS_TEST_ENV) return

  const notificationsElement = document.getElementById('fta-notifications')
  const existingFailureElement = notificationsElement.querySelector(
    '[data-status="FAILURE"]'
  )
  if (status === 'FAILURE' && existingFailureElement) {
    existingFailureElement.innerHTML = message
    return
  }

  const existingWarningElement = notificationsElement.querySelector(
    '[data-status="WARNING"]'
  )
  if (status === 'WARNING' && existingWarningElement) {
    existingWarningElement.innerHTML = message
    return
  }

  const existingSuccessElement = notificationsElement.querySelector(
    '[data-status="SUCCESS"]'
  )
  if (status === 'SUCCESS' && existingSuccessElement) {
    existingSuccessElement.innerHTML = message
    return
  }

  const divElement = document.createElement('div')
  divElement.setAttribute('data-testid', testIdNotificationMessage)
  divElement.setAttribute('data-status', status)
  divElement.style.backgroundColor = backgroundColor
  divElement.style.color = color
  divElement.innerHTML = message
  document.getElementById('fta-notifications').appendChild(divElement)

  await sleep(sleepTimer)
  document.getElementById('fta-notifications').removeChild(divElement)
}

export const parseError = (error) => {
  const errorMessage =
    error?.response?.data?.details?.message ??
    error?.response?.data?.error ??
    error?.response?.data?.message ??
    error?.message ??
    'An unknown error occurred.'

  notifyFailure(errorMessage)
  return null
}

export const parseResponse = (res) => {
  const data = res?.data?.data || res?.data
  const error = data?.error
  const message = data?.message

  if (error) {
    notifyFailure(error)
    return null
  }

  if (message && typeof message === 'string' && message.includes('Error')) {
    notifyFailure(message)
    return null
  }

  if (message && message.length > 2) {
    notifySuccess(message)
  }

  return data
}

// Sleep function
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const validateEmail = (emailAddress) =>
  emailAddress && emailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/)

export const validateUsername = (username) =>
  username &&
  username.match(/^(?=[\w.]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/) &&
  username.length > 2

export const createTestId = (testId, uniqueId) => `${testId}-${uniqueId}`

export const checkIsEntitled = (account, sport) => {
  const { user } = account
  if (!user) return false
  const { roles } = user

  if (!roles) return false

  if (roles.site_admin) return true
  if (sport && roles[`${sport}_admin`]) return true

  return account.isSubscriber
}

export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj))
