import { SITE_LINKS } from '../../utils/links'
import { atom } from 'recoil'

// Helps track users previous location
const fromLocation = atom({
  key: 'fromLocation',
  default: SITE_LINKS.HOME,
})

export default fromLocation
