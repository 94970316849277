// @ts-nocheck
import Container from '../common/container/Container'
import PropTypes from 'prop-types'
import Spacer from '../common/spacer/Spacer'
import { Component } from 'react'

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }

  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    if (this.state.error) {
      if (!window.location.href.includes('#reload')) {
        window.location = window.location.href + '#reload'

        return window.location.reload()
      }

      if (!window.location.href.includes('-again')) {
        window.location = window.location.href + '-again'

        return window.location.reload()
      }

      return (
        <Container background="white">
          <Spacer>
            <h2>Failed to load page</h2>
            <h3>Please refresh and try again</h3>
          </Spacer>
          <Spacer>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
            </details>
          </Spacer>
          <Spacer>
            <h4>{`If refresh doesn't work please return to home page`}</h4>
          </Spacer>
        </Container>
      )
    }

    // Normally, just render children
    return this.props.children
  }
}
