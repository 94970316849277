import styled from 'styled-components'
import { AccountContext } from '../../../AccountProvider'
import { COLOR } from '../../../styles/variables'
import { Fragment, lazy, useContext, useState } from 'react'
import { MUI_SIZES, SPORT, VARIANTS } from '../../../utils/constants'
import { SubHeaderStyles } from './subHeaderStyles'

const ColorButton = lazy(() => import('../../common/buttons/ColorButton'))
const Container = lazy(() => import('../../common/container/Container'))
const Dropdown = lazy(() => import('../../common/dropdown/Dropdown'))
const Spacer = lazy(() => import('../../common/spacer/Spacer'))

const SubHeaderDiv = styled.nav`
  ${SubHeaderStyles}
`

const SubHeader = () => {
  const user = useContext(AccountContext)

  const [activeDropdown, updateActiveDropdown] = useState('')

  const onMouseEnter = (sport: string) => updateActiveDropdown(sport)
  const handleMouseLeave = () => updateActiveDropdown('')

  let linkCount = 0

  return (
    <SubHeaderDiv>
      <Container background={COLOR.GREY_LIGHT}>
        <Spacer noY responsive>
          <Fragment>
            {Object.keys(SPORT).map((sport) => {
              const uppercaseSport = SPORT[sport].toUpperCase()

              linkCount++

              return (
                <Dropdown
                  dropdownName={uppercaseSport}
                  isDropdownOpen={activeDropdown === uppercaseSport}
                  key={uppercaseSport}
                  leftSpacing={linkCount !== 1}
                  onMouseLeave={handleMouseLeave}
                  onMouseOver={() => {
                    onMouseEnter(uppercaseSport)
                  }}
                />
              )
            })}
            <ColorButton
              fontSize="15px"
              link="/chat"
              size={MUI_SIZES.SMALL}
              text="Chat"
              variant={VARIANTS.TEXT}
            />
            {!user?.isSubscriber && (
              <ColorButton
                fontSize="15px"
                link="/memberships"
                size={MUI_SIZES.SMALL}
                text="Memberships"
                variant={VARIANTS.TEXT}
              />
            )}
          </Fragment>
        </Spacer>
      </Container>
    </SubHeaderDiv>
  )
}

export default SubHeader
