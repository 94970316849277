import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2'

const ftaConfirm = async ({
  confirmButtonText = 'Yes',
  icon = 'question' as SweetAlertIcon,
  showCancelButton = true,
  title = 'Are you sure?',
  text = 'Do you want to continue',
}): Promise<SweetAlertResult<Awaited<any>>> =>
  Swal.fire({
    title,
    text,
    icon,
    confirmButtonText,
    reverseButtons: true,
    showCancelButton,
  })

export default ftaConfirm
