// @ts-nocheck
import ColorButton from '../buttons/ColorButton'
import PropTypes from 'prop-types'
import { COLOR } from '../../../styles/variables'
import {
  MEMBERSHIP_BUTTONS,
  MUI_SIZES,
  VARIANTS,
} from '../../../utils/constants'

const CtaButton = ({
  backgroundColor,
  color,
  dataTestId,
  link,
  href,
  isSubscriber,
  text,
  onClick,
}) => {
  if (
    isSubscriber &&
    (text === MEMBERSHIP_BUTTONS.ONE || text === MEMBERSHIP_BUTTONS.TWO)
  )
    return null

  return (
    <ColorButton
      backgroundColor={backgroundColor || COLOR.WHITE}
      color={color || COLOR.BLUE_DARK2}
      dataTestId={dataTestId}
      link={link || href}
      onClick={onClick}
      size={MUI_SIZES.LARGE}
      text={text}
      variant={VARIANTS.CONTAINED}
    />
  )
}

CtaButton.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  href: PropTypes.string,
  isSubscriber: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

export default CtaButton
