// @ts-nocheck
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ContainerStyles } from './containerStyles'

const ContainerDiv = styled.div`
  ${ContainerStyles}
`

const Container = ({ children, background }) => (
  <ContainerDiv background={background}>{children}</ContainerDiv>
)

Container.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Container
